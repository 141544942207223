@import './../../mixins';
@import './../../variables';

$padding: 40px;

.OrderHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  padding-left: 4rem;
  padding-right: 4rem;
  @media (max-width: 767px) {
    margin-bottom: 1.5rem;
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 479px) {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  &__backWrapper {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding-top: 0.75rem;
    @media (max-width: 767px) {
      position: static;
      align-self: flex-start;
      margin-top: -2.25rem;
      padding-bottom: 0.6rem;
      margin-left: 10px;
    }
  }
  &__backButton {
    // color: #7c98b6;
    display: flex;
    align-items: center;
    svg {
      height: 16px;
      width: 16px;
      stroke: #0b1218b3;
    }
    font-size: 14px;
    &:hover {
      color: #7c98b6;
      text-decoration: none;
    }
  }
  // &__backButton img {
  //   left: 0;
  //   transition: left 0.3s ease;
  //   margin-right: 0.25rem;
  //   position: relative;
  // }
  // &__backButton:hover img {
  //   left: -4px;
  // }
  .h1 {
    color: $black;
    font-size: 1.5rem;
    line-height: 1.33;
    font-weight: 400;
    margin-bottom: 2px;
    flex-shrink: 0;
    a {
      color: $black;
      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
    strong {
      font-weight: bold;
    }
    @media (max-width: 767px) {
      font-size: 1.25rem;
      line-height: 1.2;
      margin-bottom: 0.25rem;
    }
  }
  &__placedDate {
    // font-size: 14px;
    // line-height: 20px;
    // color: $textLight;
    margin-bottom: 0;
  }
  &__parentOrderLink {
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 24px;
    &.loading {
      a {
        background-color: #eee;
        height: 16px;
      }
    }

    a {
      display: block;
      font-size: 14px;
      color: #516f90;
      font-weight: bold;
      width: 100%;
      border-radius: 4px;
      text-decoration: underline;

      &:hover {
        color: #537daa;
      }
    }
  }
  &__returnButtonWrapper {
    text-align: center;
  }
  &__returnButton {
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    padding: 10px 30px !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    margin-top: 0.5rem;
  }
}

.OrderList {
  // @include header-margin();
  // padding-top: $padding;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    padding-top: 2rem;
    // margin-left: -5px;
    // margin-right: -5px;
  }
  .h1 {
    color: $black;
    font-size: 1.5rem;
    line-height: 1.33;
    font-weight: bold;
    margin-bottom: $padding;
    flex-shrink: 0;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 2rem;
    }
  }
  &__table {
    background-color: #fff;
    border: 1px solid rgba(12, 19, 26, 0.2);
    // box-shadow: 0 0 0 1px inset $boxBorderColor;
    border-radius: $borderRadiusBase;
    flex-grow: 1;
    flex-shrink: 0;
    padding-bottom: 1.5rem;
  }
  &__message {
    margin-top: 1rem;
    margin-left: 1.5rem;
    color: #516f90;
    font-size: 12px;
    a {
      color: #001234;
      font-weight: 500;
    }
  }
}

.OrderListTable {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (max-width: 767px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .text-blue {
    color: $textLight !important;
  }
  .text-green {
    color: $green !important;
  }
  .text-red {
    color: $red !important;
  }
  .text-properCase {
    text-transform: capitalize;
  }
  .fw-medium {
    font-weight: 500;
    text-decoration: underline;
  }
  .fw-bold {
    font-weight: bold;
    text-decoration: underline;
  }
  .w-10 {
    width: 10%;
  }
  .w-20 {
    width: 20%;
  }
  .w-30 {
    width: 30%;
  }
  &__head {
    // padding-top: 0.75rem;
    position: sticky;
    top: 104px;
    background-color: white;
    border-bottom: 1px solid rgba(12, 19, 26, 0.2);
    @media (max-width: 767px) {
      padding-top: 0;
    }
  }
  &__row {
    display: flex;
    padding: 20px 0;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:nth-child(2n) {
      background-color: #f6f6f6;
    }
  }
  &__head &__cell {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: rgba(8, 19, 28, 0.5);

    @media (max-width: 767px) {
      width: 50%;
    }
    &--name {
      padding-left: 1rem;
    }
    @media (max-width: 767px) {
      &--name {
        padding-left: 0;
      }
      &--total {
        text-align: right;
      }
    }
  }
  &__body &__cell {
    font-size: 14px;
    line-height: 20px;
    color: #08131c;
  }
  &__body &__cell {
    &--name {
      padding-left: 1rem;
      // font-weight: bold;
      // color: var(--theme__primary--color, #001234);
    }
    &--status {
      text-align: center;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      &--tag .optional {
        display: none;
      }
    }
    @media (max-width: 767px) {
      &--name {
        width: 50%;
        padding-left: 0;
        margin-bottom: 4px;
      }
      &--date {
        order: 1;
        width: 50%;
      }
      &--price {
        text-align: right;
        width: 50%;
        margin-bottom: 4px;
      }
      &--serviceText {
        order: 4;
        width: 100%;
        text-align: center;
        margin-top: 0.75rem;
        &:empty {
          margin-top: 0;
        }
      }
      &--status {
        order: 1;
        text-align: right;
        width: 50%;
      }
      &--tag {
        order: 3;
        width: 100%;
        margin-top: 0.75rem;
        &:empty {
          margin-top: 0;
        }
      }
    }
  }
  &__body &__row {
    &:hover {
      // background-color: $hoverWhite;
      cursor: pointer;
      text-decoration: none;
      @include primary-bg-color-var4;
    }
    &:last-child {
      border-bottom: none;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  &__noOrders {
    padding: 2rem 0.5rem 1rem;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }
  &__footer {
    text-align: center;
    padding-top: 1.5rem;
  }
  &__tag {
    border-radius: 3px;
    font-size: 14px;
    line-height: 16px;
    padding: 4px 10px;
    display: inline-block;
    background-color: #e9fbff;
    color: #36acc6;
    text-align: center;
  }
  .loadMore {
    // color: var(--theme__primary--color, $primaryColor);
    font-weight: 500;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &:focus {
      outline: none;
    }
  }
}
