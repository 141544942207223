.qrShippingLabel {
  min-height: 100vh;

  &__logoBanner {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0 2px 8px 0 rgba(140, 140, 140, 0.15);

    img {
      height: 50px;
    }

    h2 {
      margin: 10px 0;
      font-size: 24px;
    }
  }

  &__container {
    padding: 50px 0 80px;
    max-width: 830px;
    margin: 0 auto;
  }

  &__header {
    width: calc(100% - 200px);
    margin: 0 auto 50px;
    display: flex;
  }
  &__qrIcon {
    padding-top: 8px;
    svg {
      width: 102px;
      height: 102px;
      & > g > g {
        fill: var(--theme__primary--color, $primaryColor);
      }
    }
  }
  &__headerContent {
    margin-left: 42px;
  }
  &__headerTitle {
    width: calc(100% - 220px);
    // font-size: 34px;
    // font-weight: 500;
    // color: #20252a;
    margin-bottom: 20px;
  }
  &__headerDescription {
    font-size: 14px;
    color: #33475b;
    line-height: 1.4;
    margin-bottom: 30px;
  }
  &__inputWrapper {
    width: calc(100% - 160px);
    label {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.4;
      color: #20252a;
      margin-bottom: 14px;
    }
    input[type='number'] {
      padding: 6px 12px;
      background-color: #ffffff;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      border: none;
      outline: none;
      font-size: 14px;
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }

      &::placeholder {
        color: #a6afb8;
      }
    }
    button {
      min-width: unset;
      margin-left: 20px;
      width: 82px;
      svg {
        transform: rotate(80deg) scale(0.9);
        transition: 0.2s;
        & > g {
          fill: #fff;
        }
      }
      &:not(:disabled):hover svg {
        transform: rotate(90deg) scale(1.1);
      }
      &:disabled {
        background-color: #cccccc;
        border-color: #cccccc;
        opacity: 1;
      }
    }
  }
  &__inputRow {
    display: flex;
  }
}

.qrLabelLocations {
  &__tableHeader,
  &__listItem {
    display: flex;
  }

  &__tableHeader {
    padding: 16px;
    position: relative;
    // box-shadow: 0 2px 4px 0 rgba(90, 91, 255, 0.1);
    // background-color: #ffffff;
  }

  &__headerCol,
  &__listItemCol {
    width: 50%;
  }

  &__headerCol {
    font-size: 10px;
    color: #33475b;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    &.compact {
      height: 204px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        border-left: 0;
        // background: #ffffff;
        @include primary-bg-color-var4;
      }

      // &::-webkit-scrollbar-track {
      //   background: none;
      // }

      &::-webkit-scrollbar-thumb {
        @include primary-bg-color-var1;
        border-radius: 0;
        // @media screen and (max-width: $break-small) {
        //   background: none;
        // }
      }
    }
  }

  &__listItem {
    padding: 16px;
    align-items: center;
    // background-color: rgba(187, 187, 255, 0.1);
    // background-color: #ebf2ff;
    @include primary-bg-color-var3;

    &:nth-child(2n) {
      background-color: transparent;
    }
  }

  &__listItemCol {
    font-size: 14px;
    color: #33475b;
    line-height: 1.3;

    &.address {
      padding-right: 100px;
    }
  }

  &__notSearched,
  &__errorLocations {
    width: 50%;
    font-size: 14px;
    color: #33475b;
    line-height: 1.3;
    padding: 10px 32px;
    margin: 0 auto;
    transform: translateY(40px);
    background-color: rgba(187, 187, 255, 0.1);
  }

  &__loadingLocations {
    display: flex;
    flex-direction: column;

    & > div {
      height: 68px;
      padding: 16px;
      display: flex;

      &::before,
      &::after {
        content: '';
        width: 50%;
      }
      &::before {
        margin-right: 60px;
        height: 100%;
        width: calc(50% - 60px);
        background-color: var(
          --theme__primary--color-variant3,
          rgba(90, 91, 255, 0.1)
        );
      }
      &::after {
        width: 25%;
        height: 70%;
        background-color: var(
          --theme__primary--color-variant4,
          rgba(90, 91, 255, 0.06)
        );
      }
    }
  }
}

@media (max-width: 800px) {
  .qrShippingLabel {
    &__header {
      width: calc(100% - 100px);
    }
    &__qrIcon {
      padding: 10px 0;
      svg {
        width: 80px;
        height: 80px;
      }
    }
  }
}

@media (max-width: 600px) {
  .qrShippingLabel {
    text-align: center;

    &__logoBanner {
      h2 {
        font-size: 20px;
        margin: 8px 0;
      }
    }

    &__container {
      padding: 20px 25px;
    }

    &__header {
      margin: 0 0 40px;
      width: 100%;
      flex-direction: column;
    }

    &__qrIcon {
      padding: 10px 0;
      svg {
        width: 80px;
        height: 80px;
      }
    }

    &__headerContent {
      margin: 0;
    }
    &__headerTitle {
      width: calc(100% - 50px);
      margin: 20px auto 20px;
      font-size: 26px;
      font-weight: 500;
    }
    &__headerDescription {
      font-size: 16px;
      color: #33475b;
      line-height: 1.6;
      margin-bottom: 50px;
    }

    &__inputWrapper {
      width: 100%;

      label {
        text-align: left;
        line-height: 1.25;
        margin-right: 100px;
      }

      input[type='number'] {
        flex: 1;
      }
    }
  }
  .qrLabelLocations {
    &__table {
      margin: 0 -15px;
    }
    &__tableHeader {
      padding: 16px 30px;
    }
    &__headerCol {
      &.address {
        text-align: left;
      }
      &.slots {
        text-align: right;
      }
    }
    &__list {
      padding: 0 5px;
      height: unset;
    }
    &__listItem {
      align-items: center;
    }
    &__listItemCol {
      width: 100%;
      text-align: left;
      &.address {
        padding-right: 10px;
      }
      &.slots {
        padding-left: 10px;
        text-align: right;
      }
    }
    &__notSearched,
    &__errorLocations {
      width: calc(100% - 60px);
      font-size: 14px;
      color: #33475b;
      line-height: 1.3;
      padding: 10px 20px;
      margin: 0 auto;
      transform: translateY(40px);
    }
    &__loadingLocations {
      & > div {
        justify-content: space-between;
      }
    }
  }
}
