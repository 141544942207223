.GiftBalanceTable {
  background-color: #fff;
  // -webkit-box-shadow: 0 0 0 1px inset #ecf1f4;
  // box-shadow: 0 0 0 1px inset #ecf1f4;
  border: 1px solid #ecf1f4;
  border-radius: 5px;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-bottom: 40px;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  &__row {
    display: flex;
    border-bottom: 1px solid #f5f8fa;
    padding: 20px 0;
    text-decoration: none;
    -webkit-transition: background-color 0.3s ease;
    -o-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;

    &:hover {
      // background-color: #fafbfc;
      cursor: pointer;
      // text-decoration: none;

      @include primary-bg-color-var4;
    }
  }
  &__head {
    position: sticky;
    top: 104px;
    background-color: #ffffff;
    z-index: 1;

    &:hover {
      background-color: #ffffff;
    }
    &--cell {
      font-size: 10px;
      text-transform: uppercase;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: rgba(8, 19, 28, 0.5);
    }
  }
  &__cell {
    font-size: 14px;
    line-height: 20px;
    color: #33475b;
    &.gift-number {
      font-weight: 500;
    }
  }
  @media (max-width: 1023px) {
    &__hidden {
      display: none;
    }
  }
  .Table {
    &__card {
      &-body {
        margin-bottom: 10px;
        border-bottom: none;
        border-radius: 5px;
        -webkit-box-shadow: 0 4px 4px 0 rgba(133, 133, 160, 0.05);
        box-shadow: 0 4px 4px 0 rgba(133, 133, 160, 0.05);
        border: none;
        background-color: #fff;
        padding: 15px;
        font-size: 14px;
        line-height: 20px;
        color: #33475b;
        .font-increase {
          font-weight: 500;
        }
      }
    }
  }
  &__empty {
    padding: 2rem 0.5rem 1rem;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }

  @media only screen and (max-width: 767px) {
    .responsive__heading {
      display: none;
    }

    .GiftBalanceTable {
      padding-left: 0px;
      padding-right: 0px;
      background-color: transparent;
      box-shadow: none;
    }
    &.responsive__table {
      background-color: transparent;
      box-shadow: none;
      padding-left: 0px;
      padding-right: 0px;
      border-bottom: none;
    }
  }
}
.GiftBalanacePagination {
  text-align: center;
  color: var(--theme__primary--color, $primaryColor);
  font-weight: 500;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  cursor: pointer;
  margin-top: 15px;

  &:hover {
    opacity: 0.7;
  }
}
div.GiftBalanceTable__row:last-of-type {
  border-bottom: none;
}

// @media (max-width: 1023) {
//   span.hidden {
//     display: none;
//   }
// }
