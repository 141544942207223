@import './../../mixins';
@import './../../variables';

$padding: 40px;

.OrderWrapper {
  // @include header-margin();
  padding-top: $padding;
  margin-bottom: 40px;
  @media (max-width: 1199px) {
    // margin-left: 1rem;
    // margin-right: 1rem;
  }
  @media (max-width: 767px) {
    // margin-left: -5px;
    // margin-right: -5px;
    // padding-left: 1rem;
    // padding-right: 1rem;
  }
}
