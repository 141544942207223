.container {
  margin-bottom: 6px;
  background-color: var(--color, #eee);

  &.circle {
    border-radius: 100%;
  }

  &[data-animate='true'] {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      left: -50%;
      //   left: 50%;
      top: 0;
      width: 30%;
      height: 100%;
      transform: skewX(-30deg);
      filter: blur(4px);
      background-color: var(--highlight-color, #ddd);
      animation: load-skeleton 0.8s infinite ease-in-out;
    }
  }
}

@keyframes load-skeleton {
  0% {
    left: -50%;
  }
  80%,
  100% {
    left: 150%;
  }
}
