$track-color: #dddddd;

.container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.brief {
  margin: 0;
  width: 80%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  color: rgba(12, 19, 26, 0.7);
}

.card {
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.eta {
  min-width: 140px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: unset;
    bottom: -15px;
    right: unset;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }

  h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: rgba(12, 19, 26, 0.7);
  }

  strong {
    line-height: 1;
    font-size: 36px;
    font-weight: 900;
    color: rgba(12, 19, 26, 0.7);
  }
}

.summary {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  justify-content: space-between;

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    color: rgba(12, 19, 26, 0.7);

    span {
      color: rgba(12, 19, 26, 0.55);
    }
  }

  a {
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: underline;
    color: rgba(12, 19, 26, 0.7);

    svg {
      margin-left: 2px;
      transform: scale(0.8) translateY(-1px);
    }
  }
}

.progress {
  padding: 0;
  margin: 8px 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  min-height: 200px;

  &::before {
    content: '';
    border-radius: 4px;
    background-color: $track-color;
    position: absolute;
    height: 100%;
    width: 8px;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  &::after {
    content: '';
    border-radius: 4px;
    background-color: var(--bar-color);
    position: absolute;
    width: 8px;
    height: var(--bar-size);
    left: 5px;
  }

  li {
    width: 18px;
    height: 18px;
    background-color: $track-color;
    border-radius: 50%;
    position: relative;

    & > div {
      font-size: 12px;
      top: 1px;
      left: 32px;
      line-height: 1.3;
      color: rgba(12, 19, 26, 0.7);
      // white-space: nowrap;
      position: absolute;
      width: 140px;
    }

    &:last-child > div {
      width: unset;
    }

    &.done {
      background-color: var(--bar-color);
    }

    &.active {
      background-color: var(--bar-color);

      & > div {
        font-weight: bold;
      }

      &::after {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $track-color;
      }
    }
  }
}

.currentAddress {
  display: flex;
  flex-direction: column;
}

.address {
  display: flex;
  justify-content: space-between;

  .from,
  .to {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 1.42;
    color: #53595f;

    p {
      margin: 0;
      font-weight: bold;
    }
  }

  .from {
    display: none;
  }

  .to {
    text-align: left;
    padding-left: 32px;
  }
}

.mobile {
  .title {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .content {
    padding: 18px 16px;
    flex-direction: column;
  }

  .eta {
    &::after {
      width: 100%;
      bottom: -18px;
      border-top: 1px solid rgba(228, 228, 228, 0.6);
    }

    h5 {
      font-size: 14px;
    }

    strong {
      font-size: 36px;
    }
  }

  .header {
    margin-bottom: 0px;
    position: absolute;
    top: 9px;
    right: 0;
    z-index: 10;

    p {
      display: none;
    }

    span {
      font-size: 11px;

      svg {
        margin-left: 0px;
        transform: scale(0.7) translateY(0px);
      }
    }
  }

  .progress {
    margin: 8px 0 32px;
    flex-direction: column;
    min-height: 200px;

    &::before {
      height: 100%;
      width: 8px;
      left: 5px;
    }

    &::after {
      width: 8px;
      height: var(--bar-size);
      left: 5px;
    }

    li {
      & > div {
        font-size: 12px;
        top: 1px;
        left: 32px;
      }
    }
  }
}

@media (min-width: 400px) {
  .brief {
    width: 100%;
  }
}

@media (min-width: 500px) {
  .header {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }

  .progress {
    min-height: unset;
    flex-direction: row;
    margin: 0 0 48px;

    &::before {
      height: 8px;
      width: 100%;
      left: 0;
    }

    &::after {
      width: var(--bar-size);
      height: 8px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    li {
      & > div {
        font-size: 11px;
        top: calc(100% + 8px);
        left: 0;
      }

      &:last-child > div {
        left: unset;
        right: 0;
      }
    }
  }

  .address {
    .from {
      display: flex;
      text-align: left;
    }

    .to {
      text-align: right;
    }
  }
}

@media (min-width: 640px) {
  .card {
    flex-direction: row;
    gap: 50px;
  }

  .eta {
    &::after {
      width: unset;
      height: 100%;
      bottom: unset;
      right: -25px;
      border: none;
      border-left: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
}

@media (min-width: 700px) {
  .container {
    gap: 16px;
  }

  .brief {
    font-size: 16px;
  }

  .card {
    padding: 24px;
  }

  .eta {
    min-width: 160px;

    h5 {
      font-size: 16px;
    }

    strong {
      font-size: 46px;
    }
  }
}
