.OrderNameModal {
  max-width: 700px;
  &__text {
    color: #516f90;
    margin-bottom: 0;
    font-size: 15px;
  }
  &__imageWrapper {
    text-align: center;
  }
  &__image {
    max-width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
  .modal-footer {
    justify-content: center;
    border-top: 0;
    padding-bottom: 1.5rem;
  }
  &__button {
    height: 38px;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
  }
}
