.bold {
  &__weight {
    &-600 {
      font-weight: 600;
    }
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.font-size-16 {
  font-size: 16px;
}
.text-underline {
  text-decoration: underline;
}
