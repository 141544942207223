.PoweredByBar {
  // margin: 0 -15px;
  // margin-top: 56px;
  border-top: solid 1px #ecf1f4;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
  &__title {
    display: flex;
    align-items: center;
    color: inherit;
    // fill: #a0a0a0;
    font-size: 14px;
    text-decoration: none;
    transition: color 0.2s ease, fill 0.2s ease;
    &:hover {
      // text-decoration: none;
      // color: var(--theme__primary--color, $primaryColor);
      // fill: var(--theme__primary--color, $primaryColor);
      color: inherit;
    }
  }
  &__logo {
    margin-left: 5px;
    transform-origin: left center;
    transform: translateY(-1.5px) scale(1.4);
  }

  // svg {
  //   fill: $primary-color-var1;
  // }
  // @include primary-bg-color-var3;
}
