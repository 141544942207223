@import './../../mixins';
@import './../../variables';
@import './cancel-modal';

$padding: 40px;

.OrderServiceTrack {
  @import './awaiting-status-updates';
  @import './shipment-information';
  @import './shipping-event';
  @import './product';
  @import './cancel';
  @import './pickup-scheduler';

  &__body,
  .OrderHeader {
    @media (max-width: 767px) {
      // margin-left: -1rem;
      // margin-right: -1rem;
    }

    max-width: 975px;
    margin-right: auto;
    margin-left: auto;
  }

  &__box {
    // background-color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    // box-shadow: 0 0 0 1px $boxBorderColor;
    // @include primary-border-color-only-var3;

    @media (max-width: 767px) {
      border-radius: 0;
    }
  }

  &__boxTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: normal;
    // color: var(--theme__primary--color, $primaryColor);

    @media (max-width: 767px) {
      padding: 1rem 1.25rem;
      flex-direction: column;
      align-items: flex-start;

      .secondary-link,
      .primary-link {
        margin: 0.5rem 0 0 !important;
      }
    }
  }

  // .h2 {
  //   font-size: 18px;
  //   line-height: 19px;
  //   color: #2a2f3b;
  //   font-weight: bold;
  //   margin-bottom: 0;

  //   @media (max-width: 767px) {
  //     font-size: 1rem;
  //     line-height: 1.25;
  //   }
  // }

  .button {
    color: white;
    background-color: var(--theme__primary--color, $primaryColor);
    box-shadow: none;
    border: 1px solid var(--theme__primary--color, $primaryColor);
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 156px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  .outline-button {
    color: var(--theme__primary--color, $primaryColor);
    background-color: transparent;
    box-shadow: none;
    border: 1px solid var(--theme__primary--color, $primaryColor);
    font-size: 14px;
    display: inline-block;
    line-height: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 156px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      color: white;
      background-color: var(--theme__primary--color, $primaryColor);
    }
  }

  &__serviceWrapper {
    margin-left: 1.5rem;
    border-bottom: 1px solid #eaf1f8;
    margin-right: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    @media (max-width: 1023px) {
      margin-right: 0;
    }

    @media (max-width: 767px) {
      padding-left: 1rem;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      padding-bottom: 1.5rem;
    }

    @media (max-width: 479px) {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__serviceTop + .Product {
    margin-top: 1.5rem;
  }

  &__serviceTopMain {
    @media (max-width: 576px) {
      margin-bottom: 1rem;
    }
  }

  // If Fulfillment Item is not showing, need to add enough padding on top
  &__boxTop + &__serviceWrapper {
    padding-top: 33px;
  }

  .retex-title {
    // font-size: 20px;
    // color: var(--theme__primary--color, $primaryColor);
    padding: 1.5rem;
    margin-bottom: 0;
    @media (max-width: 767px) {
      padding: 1rem;
    }
  }

  &__serviceWrapper + .retex-title {
    padding-top: 0;
  }

  &__serviceDescription {
    font-size: 14px;
    line-height: 20px;
    color: #2a2f3b;
    margin-bottom: 0;
  }

  &__serviceTextExtra {
    font-size: 13px;
    line-height: 20px;
    color: #7c98b6;
  }

  // &__serviceNumber {
  //   font-weight: 500;
  //   font-size: 1rem;
  //   margin-bottom: 0;
  // }
  &__serviceNumberDivider {
    @media (max-width: 479px) {
      display: none;
    }
  }
  &__serviceStatus {
    @media (max-width: 479px) {
      margin-top: 0.5rem;
      display: block;
    }
  }

  .info-text {
    // color: var(--theme__primary--color, $primaryColor);
  }

  .text-green {
    color: $green !important;
  }

  .text-black {
    color: $black !important;
  }

  .text-regular {
    font-weight: 400 !important;
  }

  .text-yellow {
    color: $yellow !important;
  }

  .text-red {
    color: $red !important;
  }

  .fw-medium {
    font-weight: 500 !important;
  }

  &__paymentButton {
    margin-top: 0.5rem;
  }

  &__shippingLabelButton {
    margin-top: 0.5rem;
  }

  &__total {
    border: none !important;
    border-radius: 5px !important;
    overflow: hidden;
  }

  &__totalDifferenceInformation {
    background-color: #fffdfb;
    color: #ff7e4e;
    font-size: 14px;
    text-align: center;
    padding: 0.5rem;
    border-radius: 4px;
    margin-top: 1rem;
    border: 1px solid fade-out(#ff7e4e, 0.75);
  }

  &__totalHeading {
    font-size: 18px;
    line-height: 1.33;
    color: #001234;
    padding-bottom: 1.5rem;
  }

  &__shippingHeading {
    // font-size: 18px;
    padding: 1rem;
    // line-height: 1.33;
    // color: #001234;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__manualDeductionAlert {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 14px;
    padding: 14px 24px;
    border-radius: 8px;
    border: solid 1px #ff9c99;
    background-color: #ffefef;

    p {
      margin: 0;
      font-size: 14px;
      color: #373e4c;
      line-height: 1.25;
      text-align: left;
    }

    svg g path {
      fill: #ff9c99;
    }
  }

  .line-divider {
    // height: 2px;
    // border-right: 1px dotted #cbd6e2;
    border-right: 1px dotted rgba(0, 0, 0, 0.1);
    @media (max-width: 767px) {
      // border-top: 1px dotted #cbd6e2;
      border-top: 1px dotted rgba(0, 0, 0, 0.1);
    }
    // margin-bottom: 1em;
    // margin-top: 1em;
  }

  .sub-total-text {
    // font-size: 13px;
    // font-weight: 500;
    // line-height: 1.85;
    // letter-spacing: 0.9px;
    // color: #8c97b4;

    &.final {
      font-size: 13px;
      font-weight: 600;
      line-height: 1.85;
      letter-spacing: 0.9px;
      // color: #5a5bff;
      color: #0012348c;
    }
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;

    .credit-amount {
      padding: 4px 10px;
      // background-color: #a4e1f1;
      color: #000;
      font-size: 10px;
      font-weight: 500;

      @include primary-bg-color-var1;
    }
  }

  .waiver-description {
    margin-top: 5px;
    text-align: right;
    font-size: 12px;
    color: var(--theme__primary--color, $primaryColor);
    border-radius: 5px;
    letter-spacing: 0;
    font-weight: normal;
  }

  .sub-total-price {
    // font-size: 18px;
    // line-height: 1.33;
    // text-align: right;
    // color: #001234;

    &.negative {
      color: #ff9c99;
      font-weight: 500;
    }

    &.final-price {
      // font-size: 18px;
      // font-weight: bold;
      // line-height: 1.33;
      // text-align: right;
      color: var(--theme__primary--color, $primaryColor);

      .finalPrice__presentment {
        margin-top: 4px;

        &--currency {
          margin-right: 8px;
          font-size: 12px;
          font-weight: 400;
          opacity: 0.7;
        }

        &--amount {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .waiver {
      display: none;
    }

    &.waive-off {
      .amount {
        font-size: 0.8em;
        opacity: 0.8;
        text-decoration: line-through;
      }

      .waiver {
        display: inline;
        margin-left: 10px;
      }
    }
  }

  .secondary-link,
  .primary-link {
    text-align: center;
    display: inline-block;
    min-width: 156px;
    font-size: 14px;
    padding: 0.4rem 1rem;
    line-height: 1;
    &:hover {
      text-decoration: none;
    }
  }
  .secondary-link {
    color: var(--theme__secondary-btn--color, $primaryColor);
    background-color: var(--theme__secondary-btn--background-color, #fff);
    border-width: var(--theme__secondary-btn--border-width, 1px);
    border-style: var(--theme__secondary-btn--border-style, solid);
    border-color: var(--theme__secondary-btn--border-color, $primaryColor);
    border-radius: var(--theme__secondary-btn--border-radius, 4px);
  }
  .primary-link {
    margin-left: 1rem;
    color: var(--theme__primary-btn--color, #fff);
    background-color: var(
      --theme__primary-btn--background-color,
      $primaryColor
    );
    border-width: var(--theme__primary-btn--border-width, 1px);
    border-style: var(--theme__primary-btn--border-style, solid);
    border-color: var(--theme__primary-btn--border-color, $primaryColor);
    border-radius: var(--theme__primary-btn--border-radius, 4px);
  }
}
