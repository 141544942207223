$max-size: 659px;

.next-step {
  padding-bottom: 1px;
  // border-bottom: 2px dashed #cbd6e2;
}
.next-step-heading {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.86;
  color: #001234;
  cursor: pointer;

  > .icon-only {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-left: 3px;
  }
  img {
    width: auto !important;
  }
}

.next-step-details {
  padding-block-start: 1em;
  .bullet-points {
    height: 25px;
    width: 25px;
    line-height: 21px;
    // text-align: center;
    // border-radius: 50px;
    // background: #ffe884;
    // color: #5c501d;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;

    @include primary-bg-color-var3;
  }
  .details {
    // font-size: 13px;
    // font-weight: normal;
    // font-style: normal;
    // font-stretch: normal;
    // padding-left: 0.4em;
    // letter-spacing: normal;
    // color: #516f90;
  }
}

.next-step__item {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  & + .next-step__item {
    margin-top: 0.4rem;
  }
  .bullet-points {
    flex-shrink: 0;
    margin-right: 0.25rem;
  }
}

@media (max-width: $max-size) {
  .next-step {
    margin-top: 0em;
  }
}
