.orderServiceCancelModal {
  .confirmCancel {
    &__label {
      text-align: left;
    }
    &__label label {
      font-size: 14px;
      line-height: 1.14;
      color: #8998a6;
    }
    &__input {
      box-shadow: none;
      border: 0;
      border-bottom: 1px solid #c6dae8;
      background-color: #ffffff;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: normal;
      text-align: left;
      color: #222c35;
      border-radius: 0px;
      padding-left: 0px;

      &:hover {
        border-color: hsl(0, 0%, 70%);
      }
      &:focus {
        border-bottom: 1px solid var(--theme__primary--color, $primaryColor);
        color: #222c35;
      }
      &.is-invalid {
        box-shadow: none;
        border-bottom: 1px solid $red;
      }
    }
    &__error {
      font-size: 14px;
      color: $red;
      text-align: left;
      margin-top: -0.75rem;
    }
    &__select + .confirmCancel__error {
      margin-top: 0;
    }
    &__select {
      text-align: left;
      position: relative;
      z-index: 10;
      &.is-invalid .Select-control {
        border-bottom: 1px solid $red;
      }
      .Select-control {
        border: 0;
        border-radius: 0px;
        border-bottom: 1px solid #c6dae8;
        padding: 0px;
        .Select-value {
          padding-left: 0px !important;
        }
      }
      .Select-menu {
        height: 115px;
      }
    }
  }
  .Select.is-focused:not(.is-open) > .Select-control {
    border-bottom: 1px solid #3e3eff !important;

    box-shadow: none !important;
    background: #fff;
  }
  .Select-input {
    padding-left: 0px;
  }
}
