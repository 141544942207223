@import './../../mixins';
@import './../../variables';

$padding: 40px;

.OrderDetails {
  @media (max-width: 767px) {
    // margin-left: -1rem;
    // margin-right: -1rem;
  }
  &__fulfillment,
  &__serviceRequests {
    background-color: #fff;
    // border-radius: 5px;
    // box-shadow: 0 0 0 1px $boxBorderColor;
    padding: 0px 1.5rem 20px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0;
    }
  }

  &__fulfillmentTop,
  &__serviceRequestsTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    @media (max-width: 767px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  // .h2,
  .DeliveryStatus {
    // font-size: 18px;
    // line-height: 19px;
    // font-weight: bold;
    // color: #2a2f3b;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 1rem;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .outline-button {
    color: var(--theme__primary--color, $primaryColor);
    background-color: transparent;
    box-shadow: none;
    display: inline-block;
    border: 1px solid var(--theme__primary--color, $primaryColor);
    font-size: 14px;
    line-height: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 156px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center;
    text-decoration: none;

    &:hover {
      color: white;
      background-color: var(--theme__primary--color, $primaryColor);
    }
  }

  .button {
    color: white;
    background-color: var(--theme__primary--color, $primaryColor);
    box-shadow: none;
    border: 1px solid var(--theme__primary--color, $primaryColor);
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 156px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  &__priceWrapper {
    background-color: #fff;
    // border-radius: 5px;
    // box-shadow: 0 0 0 1px $boxBorderColor;
    padding: 1.5rem;
    margin-bottom: 20px;
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0;
    }
  }

  &__totalGroup {
    border-right: 1px solid #dfe3eb;
    width: 50%;
    padding-right: 1.5rem;
    @media (max-width: 767px) {
      border-right: 0;
      border-bottom: 1px solid #dfe3eb;
      padding-bottom: 1.5rem;
      padding-right: 0;
      width: 100%;
    }
  }
  &__shippingDetails {
    width: 50%;
    padding-left: 1.5rem;
    @media (max-width: 767px) {
      padding-top: 1.5rem;
      padding-left: 0;
      width: 100%;
    }
  }

  // .h4 {
  //   font-size: 18px;
  //   line-height: 1.33;
  //   color: #8c97b4;
  //   margin-bottom: 0;
  // }

  &__totalPriceWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 1.5rem;
    @media (max-width: 767px) {
      margin-bottom: 1.25rem;
    }
  }

  &__totalPrice {
    // line-height: 1.33;
    // color: $black;
    // font-weight: bold;
    text-align: right;

    // &--usd {
    // font-size: 18px;
    // }

    &--custom {
      margin-top: 4px;
      // font-size: 14px;
      opacity: 0.7;
    }
  }
  &__priceBreakdown {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__priceBreakdown + &__priceBreakdown {
    border-top: 1px dashed #cbd6e2;
  }
  &__priceBreakdownKey {
    // font-size: 14px;
    // line-height: 20px;
    // color: $textColor;
    // }
    // &__priceBreakdownValue {
    // font-size: 14px;
    // line-height: 20px;
    // color: $black;
    font-weight: normal;
  }
  &__shippingDetails h1 {
    margin-bottom: 2rem;
    @media (max-width: 767px) {
      margin-bottom: 1rem;
    }
  }
  &__address {
    // font-size: 14px;
    line-height: 24px;
    // margin-bottom: 1.5rem;
    // color: $black;
  }
  &__comment {
    font-size: 12px;
    line-height: 1.5;
    color: $textLight;
    a {
      color: $black;
      text-decoration: underline;
      font-weight: 500;
    }
  }
}

.FulfillmentItem {
  border-top: 1px solid #eaf0f9;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  &:last-child {
    margin-bottom: -20px;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__image {
    width: 64px;
    height: 64px;
    margin-right: 1rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }
  }

  &__content {
    width: 50%;
    padding-right: 1rem;
    @media (max-width: 767px) {
      flex-grow: 1;
      padding-right: 0;
      padding-bottom: 0.75rem;
    }
  }

  &__title {
    // font-size: 1rem;
    // line-height: 1.25;
    // color: $black;
    margin-bottom: 4px;
    // font-weight: 500;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 1.29;
    }
  }

  // &__subtitle {
  // font-size: 14px;
  // line-height: 24px;
  // color: $textColor;
  // }

  &__extra {
    font-size: 13px;
    line-height: 20px;
    color: #7c98b6;
  }

  &__updates {
    padding-right: 1rem;
    width: 30%;
    @media (max-width: 767px) {
      width: 100%;
      padding-left: 5rem;
    }
  }

  &__status {
    color: $textColor;
    font-size: 14px;
    line-height: 20px;
  }

  &__button {
    margin-left: auto;
    @media (max-width: 767px) {
      margin-left: 5rem;
      margin-top: 0.75rem;
    }
  }

  &--serviceRequest &__title {
    font-weight: bold;
    color: $black;
    font-size: 14px;
    line-height: 20px;
  }

  &--serviceRequest &__status {
    color: $black;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &--serviceRequest &__approval {
    font-size: 14px;
    line-height: 20px;
  }

  .text-green {
    color: $green !important;
  }
  .text-yellow {
    color: $yellow !important;
  }
  .text-red {
    color: $red !important;
  }
}

.OrderServiceRow {
  border-top: 1px solid #eaf0f9;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: -20px;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @media (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__text {
    width: 45%;
    padding-right: 1rem;
    color: #33475b;
    @media (max-width: 599px) {
      width: 100%;
    }
  }
  &__date {
    width: 25%;
    // color: #8c97b4;
    padding-left: 1rem;
    padding-right: 1rem;
    // font-size: 14px;
    @media (max-width: 599px) {
      padding-left: 0;
      padding-top: 0.5rem;
      width: 100%;
    }
  }
  &__link {
    text-align: right;
    width: 30%;
    padding-left: 1rem;
    @media (max-width: 599px) {
      text-align: left;
      padding-left: 0;
      padding-top: 0.5rem;
      width: 100%;
    }
  }
}
