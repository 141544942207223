.AwaitingStatusUpdates {
  display: flex;
  align-items: center;

  &__illustration {
    width: 50%;
    border-right: 1px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8rem 1.5rem;

    // .h4 {
    // margin-top: 1.5rem;
    // color: #516f90;
    // font-size: 1rem;
    // font-weight: normal;
    // text-align: center;
    // line-height: 1.5;
    // }
  }

  &__nextSteps {
    // width: 50%;
    // padding: 3rem 1.5rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
  }

  .next-step {
    margin-top: 0;
    border-bottom: 0;
  }

  .next-step-details .details {
    // font-size: 14px;
    // line-height: 1.5;
    // margin-top: -2px;
  }

  .next-step__item + .next-step__item {
    margin-top: 1rem;
  }

  &__qrLinks {
    margin-bottom: 1rem;
    border-bottom: 2px dashed #cbd6e2;
  }
}

@media (max-width: 767px) {
  .AwaitingStatusUpdates {
    flex-direction: column;
    &__illustration {
      border-bottom: 1px solid #e5e5e5;
      border-right: 0;
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    &__nextSteps {
      width: 100%;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}
