$borderColor: #eaf0f9;
$black: #001234;
$gray: #4b5c73;
$purple: #5a5bff;
$linkColor: #444444;

.FormWrapper {
  display: block;
  margin: 0 100px;

  @media (max-width: 1200px) {
    margin: 0 75px;
  }

  @media (max-width: 1023px) {
    margin: 0 50px;
  }

  @media (max-width: 599px) {
    margin: 0 25px;
  }

  &.gift_return_link,
  &.support_link_wrapper {
    line-height: normal;
    display: inline-block;
    color: fade-out($linkColor, 0.1);

    span {
      padding: 0 6px;
    }
  }

  &.gift_return_link {
    font-size: 14px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  &.support_link_wrapper {
    font-size: 12px;
  }

  &__heading {
    line-height: 1.33;
    color: var(--theme__login-header--color, #212529);
    font-size: var(--theme__login-header--font-size, 24px);
    font-weight: var(--theme__login-header--font-weight, 700);
    font-family: var(
      --theme__login-header--font-family,
      var(--theme__font-family, 'Inter', sans-serif)
    );
    margin-bottom: 28px;
  }
  &__heading + .FormWrapper__description {
    margin-top: -18px;
  }
  &__description {
    font-size: 14px;
    margin-bottom: 28px;
    color: var(--theme__login-header--color, #212529);
    line-height: 1.3;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  &__linkWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 504px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  &__relatedLinks {
    font-size: 14px;
    line-height: normal;
    display: inline-block;
    color: fade-out($linkColor, 0.1);
    // margin-bottom: 8px;
    // padding-bottom: 3px;
    // padding-top: 3px;
    text-align: center;
    display: inline-block;
    i {
      margin-right: 5px;
    }
    &:hover {
      text-decoration: none;
    }
    @media (max-width: 600px) {
      line-height: 1.8;
    }
    &.login-help-link {
      img {
        width: 16px;
      }
    }
  }
}

.LoginForm {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__wrapper {
    width: 100%;
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;

    @media (max-width: 479px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__inputWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  &__label {
    display: block;
    color: var(--theme__login-label--color, #777777);
    font-size: var(--theme__login-label--font-size, 13px);
    font-weight: var(--theme__login-label--font-weight, 400);
    font-family: var(
      --theme__login-label--font-family,
      var(--theme__font-family, 'Inter', sans-serif)
    );
    margin-bottom: 4px;
  }
  &__errorMsg {
    margin-top: 4px;
    font-size: 14px;
    text-align: left;
    color: #ff4e4e;
    position: absolute;
    bottom: 3px;
    right: 0;
    opacity: 0;
    transform: translateY(4px) scale(0.8);
    animation: appear-error 0.3s forwards;

    &.default-pos {
      position: static;
    }

    &::after {
      font-size: 12px;
      content: ' ⚠';
      // content: " ⤴";
      // position: relative;
      // top: -5px;
    }

    @media (max-width: 1086px) {
      position: unset;
    }

    @media (max-width: 767px) {
      position: absolute;
    }

    @media (max-width: 347px) {
      position: unset;
    }
  }
  &__helpText {
    color: $purple;
    font-size: 14px;
    margin-bottom: 6px;
  }
  &__inputGroup {
    width: 100%;
    display: flex;
    gap: 10px;
    position: relative;
  }
  &__phoneInput {
    width: 100%;
    position: relative;

    .PhoneInputCountry {
      padding: 12px;
      margin-right: 10px;
      background-color: #fff;
      border: 1px solid rgba(12, 19, 26, 0.5);
    }

    .PhoneInputCountryIcon {
      margin-right: 8px;
    }

    .PhoneInputCountrySelectArrow {
      border-right: 1px solid rgb(33, 37, 41);
      border-bottom: 1px solid rgb(33, 37, 41);
    }
  }

  &__phoneInput input,
  &__input {
    width: 100%;
    padding: 10px;
    color: var(--theme__login-input--color, #0c131a);
    font-size: var(--theme__login-input--font-size, 16px);
    font-weight: var(--theme__login-input--font-weight, 400);
    font-family: var(
      --theme__login-input--font-family,
      var(--theme__font-family, 'Inter', sans-serif)
    );
    border-width: var(--theme__login-input--border-width, 1px);
    border-style: var(--theme__login-input--border-style, solid);
    border-color: var(--theme__login-input--border-color, #aaaaaa);
    border-radius: var(--theme__login-input--border-radius, 4px);

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: var(
        --theme__login-input-placeholder--color,
        rgba(75, 91, 114, 0.5)
      );
    }

    &#order_number {
      padding-right: 34px;
    }
  }

  &__phoneInput.is-invalid input,
  &__input.is-invalid {
    border-color: var(--theme__login-input--invalid-border-color, $red);
  }

  &__switchView {
    display: block;
    background-color: transparent;
    color: rgba(12, 19, 26, 0.7);
    text-align: left;
    font-size: 14px;
    // margin-top: -12px;
    padding-top: 3px;
    cursor: pointer;
    padding-bottom: 3px;
    padding-left: 0;
    padding-right: 0;
    border: none;
    font-weight: 400;
    // margin-bottom: 36px;
    text-decoration: underline;
    &:focus {
      outline: none;
    }
    &:hover {
      color: rgba(12, 19, 26, 1);
    }
  }

  &__button {
    width: 100%;
    height: 46px;
    font-size: 16px;
  }

  &__formError {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
}

@keyframes appear-error {
  from {
    opacity: 0;
    transform: translateY(4px) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
