.link {
  position: fixed;
  top: 24px;
  left: 36px;
  z-index: 2;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px 8px 8px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  backdrop-filter: blur(2px);

  &:hover {
    color: inherit;
    opacity: 0.9;
  }

  @media (max-width: 767px) {
    position: absolute;
    left: 8px;
    top: 12px;
    padding: 6px 12px 6px 6px;
    box-shadow: none;
    backdrop-filter: unset;
  }
}
