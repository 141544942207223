$breakpoint: 700px;

.container {
  margin: 0 auto;
}

.content {
  padding: 0 10px;
  max-width: 840px;
  margin: 0 auto;

  @media (min-width: $breakpoint) {
    padding: 0 20px;
  }
}

.trackingUpdates {
  padding: 0 20px;
  animation: appear 0.2s forwards;
}

.storeTitle {
  margin: 0;
}

.gridRow {
  $gap: 20px;
  $render-width: calc(100% - #{$gap});

  margin: $gap 0;
  display: flex;
  gap: $gap;
  // min-height: 203px;
  // max-height: 406px;

  .recommendation {
    width: calc(#{$render-width} * 0.7);
  }

  .banner1 {
    width: calc(#{$render-width} * 0.3);

    &.fullWide {
      height: 250px;
      width: 100%;
    }
  }

  &.desktop {
    flex-direction: row;

    .banner1 {
      border-radius: 8px;
    }
  }

  &.mobile {
    flex-direction: column;

    .recommendation {
      width: 100%;
    }

    .banner1 {
      margin: 0 -10px;
      height: 250px;
      border-radius: 0;
      width: calc(100% + 20px);
    }
  }

  @media (min-width: $breakpoint) {
    $gap: 42px;
    $render-width: calc(100% - #{$gap});

    gap: $gap;
    margin: 30px 0;

    .recommendation {
      width: calc(#{$render-width} * 0.65);
    }

    .banner1 {
      width: calc(#{$render-width} * 0.35);
    }
  }
}

.banner2Container {
  margin: 30px 0;
}

.banner2 {
  // margin: 20px 0;

  &.desktop {
    height: 200px;
    // margin: 20px 0;
    width: 100%;
  }

  &.mobile {
    height: 350px;
    margin: 0px -10px;
    border-radius: 0;
    width: calc(100% + 20px);
  }

  // @media (min-width: $breakpoint) {
  //   &.desktop {
  //     margin: 30px 0;
  //   }
  // }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
