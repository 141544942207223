@import '../../index.module.scss';

.item {
  @include padded();

  &:not(:first-child) {
    @include separator(solid);
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .title {
    // font-size: 14px;
    // color: #0c131a;
    // font-weight: 600;
  }

  .price {
    // font-size: 14px;
    // font-weight: 600;
    // color: #0c131a;
    &.positive {
      color: #589d62;
    }
    &.negative {
      color: #ad2b2b;
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .entityType {
    padding: 5px 14px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;

    &.return {
      // color: #ebab63;
      // background-color: rgba(235, 172, 99, 0.1);
    }

    &.exchange {
      // color: #63c4eb;
      // background-color: rgba(99, 197, 235, 0.1);
    }
  }

  // .quantity {
  //   font-size: 12px;
  //   line-height: 1.25;
  //   color: #0c131a;
  // }

  .arrowIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 6px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f6ff;
    border: 1px solid #f1f6ff;
    transform: rotate(0deg);
    transition: 0.3s;

    &.open {
      transform: rotate(180deg);
    }

    &:hover {
      border-color: #cbd6ec;
    }
  }
}

.details {
  @include padded();
  padding-top: 0;
  padding-bottom: 16px;
  font-size: 12px;
  @include separator(solid);
  // background-color: rgba(241, 246, 255, 0.75);

  .label {
    width: 60%;
    display: flex;
    flex-direction: column;
  }

  .value {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;
  }

  .originalPrice {
    flex-direction: column;
    align-items: stretch;

    .row {
      width: 100%;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
  }

  .exchangeVariant {
    align-items: stretch;
    flex-direction: column;
    @include separator(solid);

    .header {
      padding: 0;
      width: calc(60% - 20px);
    }

    .info {
      margin-top: 14px;
      display: flex;
      align-items: center;

      strong {
        font-weight: 600;
      }
    }
  }

  .discount {
    flex-direction: column;
    align-items: stretch;
    @include separator(dashed);

    .row {
      width: 100%;
      color: #589d62;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
  }

  .incentive {
    color: #589d62;
    font-weight: 600;
  }

  .restocking {
    @include separator(dashed);
  }

  .manualDeduction {
    @include separator(dashed);
  }

  .subTotal {
    padding: 10px;
    margin: 4px -10px 8px;
    font-weight: 600;
    background-color: #fff;
  }
}

.striked {
  text-decoration: line-through;
}

.negative {
  @include negative-color();
}

.positive {
  @include positive-color();
}

// &-line {
//   height: 1px;
//   background: #cad2da;
//   margin: 10px auto;
// }
// &-smallLine {
//   background: #f9f9fc;
//   height: 1px;
//   margin: 10px auto;
// }
// &-tax {
//   font-size: 10px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.67;
//   letter-spacing: normal;
//   color: #7c98b6;
// }
// &-product {
//   font-size: 16px;
//   font-weight: 500;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   color: #222c35;
//   &-waived {
//     font-size: 12px;
//     color: var(--theme__primary--color, #000);
//     font-weight: normal;
//   }
//   @media (max-width: 565px) {
//     font-size: 14px;
//   }
// }
// &-text {
//   font-size: 14px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   text-align: right;
// }

// &-price {
//   font-size: 16px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   text-align: right;
//   color: #637280;
//   @media (max-width: 360px) {
//     font-size: 14px;
//   }
// }
// &-subtotal,
// &-deductions {
//   font-size: 12px;
//   color: #222c35;
//   font-weight: 600;
//   line-height: 1.82;
//   font-style: normal;
//   font-stretch: normal;
//   letter-spacing: 0.4px;
//   text-transform: uppercase;

//   svg {
//     width: 12px;
//     height: 12px;
//     margin-left: 10px;
//   }
// }
// &-subtotalPrice {
//   font-size: 16px;
//   font-weight: 600;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   text-align: right;
//   color: #222c35;
//   @media (max-width: 480px) {
//     font-size: 14px;
//   }
// }
