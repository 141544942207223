$tabActiveColor: #222c35;
.Tabs {
  &.nav-tabs {
    border-bottom: none;
    .nav-link {
      border: 0;
      border-radius: 0;
      font-size: 14px;
      line-height: normal;
      font-weight: 500;
      // padding-top: 14px;
      // padding-bottom: 14px;
      // padding-left: 0;
      // padding-right: 0;
      // padding-left: 15px;
      // padding-right: 15px;
      // margin-left: 1rem;
      // margin-right: 1rem;
      margin-left: 0;
      margin-right: 0;
      color: #73818f;
      background-color: #f6f6f6;
    }
    .nav-link:hover {
      // border-bottom: 2px solid fade-out($tabActiveColor, 0.3);
      color: fade-out($tabActiveColor, 0.3);
    }
    .nav-link.active {
      border: 0;
      // border-bottom: 2px solid $tabActiveColor;
      // color: $tabActiveColor;
      color: white;
      background-color: var(--theme__primary--color, #001234);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
