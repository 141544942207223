$background_color: var(--theme__primary--color);
$disabled_color: var(--theme__primary--color);
$hover_color: var(--theme__primary--color);
$width: (
  default_width: 384px,
  default_height: 50px,
  large_width: 588px,
  large_height: 50px
);
/*Button comman properties*/
@mixin coman {
  border-radius: 6px;
  border: 1px solid $disabled_color;
}
/*Button color properties depends on active or disable
    true: button is active
    false: button is disabled
*/
@mixin color($color) {
  @if $color== 'true' {
    background-color: $background_color;
    border-color: $background_color;
  } @else {
    background-color: $disabled_color;
    border-color: $disabled_color;
    opacity: 0.4;
  }
}

.button_ui_landing {
  @include coman;
  @include color('true');
  width: 90%;
  border-radius: var(--theme__login-input--border-radius, 4px);
  max-width: map-get($width, default_width);
  height: map-get($width, default_height);
  i {
    margin-left: auto;
  }
  &.disabled {
    @include color('false');
    cursor: not-allowed;
  }
  &:hover:not(.disabled) {
    background-color: $hover_color;
    border-color: $hover_color;
  }
  &:focus {
    box-shadow: none;
  }
  &.clear-action {
    border: solid 1px #dfe3eb;
    background-color: #ffffff;
    color: #516f90;
    &:hover:not(.disabled) {
      border-color: #dfe3eb;
      background-color: #f5f8fa;
    }
  }
  &.large {
    max-width: map-get($width, large_width);
    height: map-get($width, large_height);
  }
  .button_children {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button_children-text {
    text-align: center;
    // margin-left: auto;
    flex: 1;
  }
  .left_order {
    order: 2;
  }
}
