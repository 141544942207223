.container {
  padding: 20px 0;
  margin: 0 0 28px;
  height: 80px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
  text-align: center;
}

.content {
  flex: 1;
  max-width: 840px;
  margin: 0 auto;
}

.logo {
  height: 60px;
}

.storeTitle {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
}

@media (min-width: 400px) {
  .storeTitle {
    font-size: 24px;
  }
}
