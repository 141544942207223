@import './../mixins';
@import './../variables';

.Timeline {
  text-align: center;
  margin-bottom: 34px;
  margin-left: auto;
  margin-right: auto;
  &__circles {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 2px;
      right: 2px;
      top: calc(50% - 1px);
      border-top: 2px solid var(--theme__primary--color, $primaryColor);
    }
  }
  &__circle {
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 2px inset var(--theme__primary--color, $primaryColor);
    border-radius: 50%;
    margin-right: 40px;
    background-color: #fff;
    z-index: 2;
    &--active {
      background-color: var(--theme__primary--color, $primaryColor);
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        display: inline !important;
      }
    }
    i {
      display: none;
      font-size: 12px;
      line-height: 1;
      transform: translateY(1px);
      color: #fff;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
