.container {
  line-height: 20px;
}

.wrapper {
  display: inline-block;
}

.content {
  display: flex;
  align-items: center;
  gap: var(--gap, 8px);
  pointer-events: none;
  color: #636e79;
  position: relative;
  z-index: 1;
}

.extraInfo {
  margin: 0 -2px;
  padding: 0 2px;
  border-radius: 4px;

  &:hover {
    cursor: default;
    background-color: #fbede5;
  }
}

.tooltip {
  padding: 4px 0;
  gap: 4px !important;

  .tooltipText {
    color: #fff;
    text-align: left;
  }
}
