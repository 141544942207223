.option-icon {
  cursor: pointer;
  color: #b4c1c8;
  display: inline-block;
  background-color: transparent;
}

.option-icon.selected {
  // color: var(--theme__primary--color, $primaryColor);
  color: #0c131a;
}

.option-icon.disabled {
  cursor: not-allowed;
}
