.recommendations {
  margin: 0 15px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 20px 32px 0 rgba(124, 152, 182, 0.1);
  // opacity: 0;
  // transform: scale(0);
  // transform-origin: top;
  // overflow: hidden;

  // &.showcase {
  //   animation: stretch-vertically 0.5s forwards;

  //   .recommendations {
  //     &__header {
  //       &-title {
  //         animation: glide-in-from-left 0.3s 0.5s forwards;
  //       }

  //       &-controls {
  //         animation: glide-in-from-right 0.3s 0.5s forwards;
  //       }
  //     }

  //     &__card:nth-of-type(4n + 1) {
  //       animation: glide-up 0.3s 0.8s forwards;
  //     }
  //     &__card:nth-of-type(4n + 2) {
  //       animation: glide-up 0.3s 0.85s forwards;
  //     }
  //     &__card:nth-of-type(4n + 3) {
  //       animation: glide-up 0.3s 0.9s forwards;
  //     }
  //     &__card:nth-of-type(4n) {
  //       animation: glide-up 0.3s 0.95s forwards;
  //     }

  //     &__page-indicators {
  //       &__item {
  //         animation: shrink-horizontally 0.4s 1s forwards;
  //       }
  //     }
  //   }
  // }

  * {
    margin: 0;
    padding: 0;
  }

  &__header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &-title {
      font-size: 18px;
      font-weight: bold;
      color: #33475b;
      // transform: translateX(-20%);
      // opacity: 0;
    }

    &-controls {
      // transform: translateX(20%);
      // opacity: 0;
      i {
        line-height: 0;
        padding: 15px 10px;
        border-radius: 5px;
        transition: 0.2s;
        cursor: pointer;

        &.disabled {
          color: #d8d8d8;
          background: #fff;
          transform: scale(0.8);
          cursor: not-allowed;
        }
      }
      i ~ i {
        margin-left: 10px;
      }
    }
  }

  &__content {
    margin: 0 20px;
    padding-bottom: 20px;
    overflow: hidden;
    position: relative;
    // opacity: 0;
    // transform: translateY(20%);
  }

  &__card-stack {
    list-style: none;
    display: grid;
    grid-column-gap: 30px;
    align-items: center;
    width: 100%;
    transition: 0.5s ease-in-out;
  }

  &__card {
    align-self: flex-start;
    width: 100%;
    min-width: 0;
    cursor: pointer;
    // opacity: 0;
    // transform: translateY(20%);
    transition: 0.3s;

    a {
      text-decoration-line: none;
    }

    &__product {
      position: relative;
      transition: 0.2s;
      padding-bottom: 10px;

      &-image {
        position: relative;
        z-index: -1;
        height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        border-radius: 5px;
        transition: 0.2s;
        border: 1px solid #fff;
      }

      &-title {
        margin: 10px 0 0;
        font-size: 14px;
        font-weight: 500;
        color: #33475b;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        opacity: 0.7;
      }

      &-price {
        color: #33475b;
        font-size: 12px;
        opacity: 0.7;
      }
    }
  }

  &__card:hover {
    .recommendations__card__product {
      &-title,
      &-price {
        // color: #5a5bff;
        opacity: 1;
      }
    }
  }

  &__page-indicators {
    margin: 35px 0 10px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &__item {
      border: 4px solid #d8d8d8;
      border-radius: 50%;
      margin: 0 5px;
      // margin: 0 20px;
      // opacity: 0;
      transition: 0.3s;

      &.active {
        border-color: #5a5bff;
      }
    }
  }

  // smaller screen | tablet - landscape mode
  @media (max-width: 1200px) {
    max-width: 1440px;

    &__card-stack {
      grid-column-gap: 15px;
      transition: 0.3s ease-in-out;
    }

    &__card {
      &__product {
        &-image {
          height: 200px;
          background-position-x: center;
        }
      }
    }
  }

  //   moblie - landscape mode|tablet - portrait mode
  @media (max-width: 767px) {
    max-width: 767px;

    &__header {
      &-controls {
        i {
          display: none;
        }
      }
    }

    &__card-stack {
      grid-column-gap: 15px;
      grid-row-gap: 35px;
    }

    &__card {
      &__product {
        &-image {
          height: 200px;
          background-position-x: center;
        }
      }
    }
  }

  // mobile - portrait mode
  @media (max-width: 575px) {
    max-width: 575px;

    &__header {
      &-title {
        font-size: 17px;
      }

      &-controls {
        i {
          display: none;
        }
      }
    }

    &__card-stack {
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 15px;
      grid-row-gap: 35px;
      transition: 0.3s ease-in-out;
    }

    &__card {
      &__product {
        &-image {
          height: 150px;
        }
      }
    }
  }
}

// @keyframes stretch-vertically {
//   from {
//     transform: scaleY(0);
//     opacity: 0;
//   }
//   to {
//     transform: scaleY(1);
//     opacity: 1;
//   }
// }

// @keyframes glide-in-from-left {
//   from {
//     transform: translateX(-20%);
//     opacity: 0;
//   }
//   to {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }

// @keyframes glide-in-from-right {
//   from {
//     transform: translateX(20%);
//     opacity: 0;
//   }
//   to {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }

// @keyframes glide-up {
//   from {
//     opacity: 0;
//     transform: translateY(20%);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

// @keyframes shrink-horizontally {
//   from {
//     opacity: 0;
//     margin: 0 20px;
//   }
//   to {
//     opacity: 1;
//     margin: 0 5px;
//   }
// }

// ****************************************************************************

.mock-incomming-recommendations {
  margin: 0 15px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 20px 32px 0 rgba(124, 152, 182, 0.1);
  padding: 20px;

  $gradiant-background: linear-gradient(
    45deg,
    rgba(238, 238, 238, 0.5),
    rgba(204, 204, 204, 0.5),
    rgba(238, 238, 238, 0.5)
  );

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 5px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      height: 20px;
      width: 300px;
      background: $gradiant-background;
    }

    &-controls {
      height: 30px;
      width: 40px;
      position: relative;

      &::before {
        content: '';
        width: 30px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
        background: $gradiant-background;
      }

      &::after {
        content: '';
        width: 30px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: $gradiant-background;
      }
    }
  }

  &__content {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    &-item {
      height: 350px;
      &__product {
        height: 300px;
        background: $gradiant-background;
        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;
        }

        &::before {
          bottom: -25px;
          height: 18px;
          width: 100%;
          background: $gradiant-background;
        }

        &::after {
          bottom: -50px;
          height: 18px;
          width: 40%;
          background: $gradiant-background;
        }
      }
    }
  }

  &__indicators {
    margin: 45px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-dots {
      border: 4px solid #d8d8d8;
      border-radius: 50%;
      margin: 0 5px;
      animation: loading 0.8s infinite;

      &:nth-of-type(1) {
        animation-delay: 0;
      }

      &:nth-of-type(2) {
        animation-delay: 0.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 0.4s;
      }
    }
  }

  @media (max-width: 1200px) {
    &__content {
      &-item {
        height: 250px;
        &__product {
          height: 200px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &__header {
      &-controls {
        display: none;
      }
    }
    &__content {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 35px;
    }
  }
  @media (max-width: 575px) {
    &__header {
      &-title {
        width: 200px;
      }
    }

    &__content {
      &-item {
        height: 200px;
        &__product {
          height: 150px;
        }
      }
    }
  }
}

@keyframes loading {
  0% {
    transform: scale(1);
    border-color: #d8d8d8;
  }
  50% {
    transform: scale(1.4);
    border-color: rgba(90, 90, 255, 0.5);
  }
}
