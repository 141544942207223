@import '../index.module.scss';

.fee {
  @include padded();
  border-bottom: dashed 1px #eaf0f9;

  .content {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .amount {
    s {
      opacity: 0.5;
      // color: #222c35;
      // font-size: 12px;
      // font-weight: 400;
      margin-right: 8px;
    }
  }

  .waived {
    // font-size: 12px;
    // color: #222c35;
  }
}

.container {
  box-shadow: 0 0px 12px 0 rgba(0, 18, 52, 0);
}

.stickyContainer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  box-shadow: 0 -8px 12px 0 rgba(0, 18, 52, 0.05);
  background-color: #ffffff;
  border-radius: 6px;
  left: 0;
  z-index: 1;
}

.sticky {
  width: 698px;
  margin: auto;

  @media screen and (max-width: 698px) {
    width: 100%;
  }
}

.totalAmount {
  @include padded();
}

.finaltotal {
  font-size: 16px;
  color: #0c131a;
  font-weight: 600;
  line-height: 1.11;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 480px) {
    font-size: 14px;
  }
}

.finalPrice {
  // line-height: 0.86;
  // font-weight: 600;
  text-align: right;
  // color: #222c35;

  @media (max-width: 360px) {
    font-size: 14px;
  }

  .presentment {
    margin-top: 14px;
  }

  .presentmentCurrency {
    margin-right: 8px;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.7;
  }

  .presentmentAmount {
    font-weight: 600;
  }
}

.finalPriceWrapper {
  .amount {
    // font-size: 16px;
  }

  .label {
    // font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
  }

  .waiver {
    display: none;
  }

  &.waiveOff .amount {
    // font-size: 12px;
    // font-weight: 400;
    // color: rgba(34, 44, 53, 0.5);
    text-decoration: line-through;
  }

  &.waiveOff .waiver {
    font-size: 14px;
    display: inline;
    margin-left: 10px;
  }
}

.waiveOffMessage {
  // font-size: 12px;
  margin-top: 4px;
  // font-weight: 400;
  // color: var(--theme__primary--color, #606060);
}

.negative {
  @include negative-color();
}

.positive {
  @include positive-color();
}
