.container {
  display: flex;
  gap: var(--gap, 8px);
}

.vertical {
  flex-direction: column;
  align-items: flex-start;
}

.horizontal {
  flex-direction: row;
  align-items: center;
}

.cell {
  margin: 0;
  font-size: 14px;
  color: #637381;
  line-height: 14px;
  pointer-events: none;
}

.separator {
  width: 4px;
  height: 4px;
  background-color: #636e7996;
  border-radius: 50%;
}
