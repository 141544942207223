.uiKit__radioList {
  margin: 0;
  padding: 0;
  @import './mixins/selection';

  &.spread {
    display: flex;
    .uiKit__radio ~ .uiKit__radio {
      margin-left: 50px;
    }
  }

  &.stack {
    .uiKit__radio {
      display: flex;
    }
    .uiKit__radio ~ .uiKit__radio {
      margin-top: 15px;
    }
  }

  .uiKit__radio {
    @include selection;
    display: inline-flex;
    align-items: center;

    &--label {
      font-size: 12px;
      color: #607180;
      margin-bottom: 10px;
    }

    input + label {
      border-radius: 50%;
    }

    .selection-title.checked {
      font-weight: 600;
    }
  }
}
