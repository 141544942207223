.qrLabelLinks {
  margin-bottom: 1rem;
  font-size: 14px;

  p {
    margin: 0 0 5px;
  }

  a {
    text-decoration: underline;
    color: var(--theme__primary--color, $primaryColor);
  }
}
