@import '../../scss/variables';

.action {
  cursor: pointer;
  box-shadow: none;
  //   text-align: center;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  min-width: 156px;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:active {
    transform: scale(0.98);
  }

  &__primary {
    color: var(--theme__primary-btn--color, #fff);
    background-color: var(
      --theme__primary-btn--background-color,
      $primaryColor
    );
    border-width: var(--theme__primary-btn--border-width, 1px);
    border-style: var(--theme__primary-btn--border-style, solid);
    border-color: var(--theme__primary-btn--border-color, $primaryColor);
    border-radius: var(--theme__primary-btn--border-radius, 4px);
    text-transform: var(--theme__primary-btn--text-transform, none);
    font-family: var(
      --theme__primary-btn--font-family,
      var(--theme__font-family, inherit)
    );

    &:hover {
      color: var(--theme__primary-btn--color, #fff);
    }

    .spinner {
      color: var(--theme__primary-btn--color, #fff);
    }
  }

  &__secondary {
    color: var(--theme__secondary-btn--color, $primaryColor);
    background-color: var(--theme__secondary-btn--background-color, #fff);
    border-width: var(--theme__secondary-btn--border-width, 1px);
    border-style: var(--theme__secondary-btn--border-style, solid);
    border-color: var(--theme__secondary-btn--border-color, $primaryColor);
    border-radius: var(--theme__secondary-btn--border-radius, 4px);
    text-transform: var(--theme__secondary-btn--text-transform, none);
    font-family: var(
      --theme__secondary-btn--font-family,
      var(--theme__font-family, inherit)
    );

    &:hover {
      color: var(--theme__secondary-btn--background-color, #fff);
      background-color: var(--theme__secondary-btn--color, $primaryColor);
    }
    &:disabled:hover,
    &.noHover:hover {
      color: var(--theme__secondary-btn--color, $primaryColor);
      background-color: var(--theme__secondary-btn--background-color, #fff);
    }
  }

  &__inline {
    font-size: 14px;
    color: #011234;
    border: none;
    background-color: transparent;
    padding: 0 0 2px;
    min-width: unset;
    border-bottom: 1px solid #011234;
    text-transform: var(--theme__inline-btn--text-transform, none);
    font-family: var(
      --theme__inline-btn--font-family,
      var(--theme__font-family, inherit)
    );

    &:hover {
      color: inherit;
      opacity: 0.9;
    }
  }
}

a.action {
  text-decoration: none;
}

button.action {
  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.spinner {
  animation: spin 0.8s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
