@import './../../mixins';
@import './../../variables';

$padding: 40px;

.AccountOverview {
  // @include header-margin();
  padding-top: $padding;
  .title {
    // color: $black;
    // font-size: 1.5rem;
    // line-height: 1.33;
    // font-weight: bold;
    // flex-shrink: 0;
    // text-align: center;
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 1.2;
    }
  }
  .sub-header {
    text-align: center;
    // font-size: 0.875rem;
    width: 75%;
    margin: 0 auto;
    // font-weight: 400;
    // line-height: 1.4;

    @media (max-width: 767px) {
      text-align: left;
      width: auto;
    }
  }
  // .tab-overview {
  //   margin-top: 20px;
  // }
  .tab-orientation {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 71px;
    background-color: #fcfdff;
    z-index: 1;
  }
}
