.ShippingEvent {
  display: flex;
  padding-bottom: 1rem;
  padding-top: 1rem;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &__timestamp {
    width: 30%;
    flex-shrink: 0;
  }

  &__timestampDay {
    font-weight: 500;
    // color: back;
    font-size: 12px;
  }

  &__timestampTime {
    color: #516f90;
    font-size: 14px;
  }

  & + .ShippingEvent {
    border-top: 1px solid #e5e5e5;
  }

  &__status {
    color: $black;
    font-weight: 500;
    font-size: 14px;
  }

  &__address {
    color: #7c98b6;
    font-size: 14px;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}
