.Product {
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }
  @media (max-width: 1023px) and (min-width: 480px) {
    margin-left: 0;
  }
  &__left,
  &__right {
    width: 35%;
    display: flex;

    @media (max-width: 1023px) {
      flex-grow: 1;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__image {
    margin-right: 0.7rem;
    width: 75px;
    height: 75px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }

    @media (max-width: 767px) {
      margin-right: 1rem;
    }
  }

  &__title {
    // color: #2a2f3b;
    // font-size: 14px;
    // line-height: 20px;
    // margin-bottom: 2px;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
  }

  &__subtitle {
    // color: #516f90;
    // font-size: 14px;
    // line-height: 24px;
  }

  &__content {
    overflow: hidden;
  }

  &__icon {
    // color: var(--theme__primary--color, $primaryColor);
    // width: 24px;
    // border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    font-size: 1.25rem;
    margin-left: 1rem;
    margin-right: 1rem;
    flex-shrink: 0;

    @media (max-width: 767px) {
      transform: rotate(90deg);
      padding-bottom: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      margin-left: 9rem;
    }
    @media (max-width: 479px) {
      margin-left: auto;
    }
  }
}
