.confirmDialog {
  // text-align: center;

  &-content {
    border-radius: 12px;
    box-shadow: 0 24px 40px 0 rgba(81, 111, 144, 0.1);
    background-color: #ffffff;
    outline: none;
    border: none;
    .modal-header {
      border-bottom: none;
      .modal-title {
        width: 100%;
      }
    }
    .modal-header {
      padding: 16px;
    }
  }
  &-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      color: #8998a6;
      margin: 0;
    }

    &-divider {
      position: absolute;
      border: 1px solid#dee5f5;
      width: 100%;
      left: 0;
    }
  }

  &-footer {
    border: none;
    display: flex;
    justify-content: flex-end;
  }
  &-icon {
    margin: 16px auto;
    text-align: center;
    // }
  }
  &-title {
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #222c35;

    padding-top: 10px;
  }
  &-subTitle {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #101131;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &__body {
    &.modal-body {
      margin: 0px;
      padding: 1rem 1rem 0 1rem;
    }

    &-text {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #637280;
    }
  }
  &__button {
    width: calc(50% - 5px);
    text-transform: uppercase;
    padding: 0.8rem 1rem !important;

    &-success {
      border-radius: 6px;
      background-color: #10cfa5;
      color: white;
    }
    &-warning {
      border-radius: 5px;
      background-color: #ff7979;
    }

    &-cancel {
      border-radius: 6px;
      background-color: #ffffff;
    }
  }
  .modal-body {
    padding-top: 0px;
  }
}
