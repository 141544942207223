$headerMargin: 72px;
@mixin header-margin() {
  margin-top: $headerMargin;
}

@mixin continue-btn() {
  .continue-button {
    font-size: 16px;
    width: 90%;
    max-width: 384px;
    height: 50px;
    position: relative;

    i.fa-arrow-right {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@mixin date-picker() {
  .react-datepicker {
    border: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);

    &__triangle {
      margin-top: -6px !important;
      border-bottom-color: var(
        --theme__primary--color,
        $primaryColor
      ) !important;
    }
    &__triangle::before {
      top: 0 !important;
      border-bottom-color: var(
        --theme__primary--color,
        $primaryColor
      ) !important;
    }
    &__header {
      background-color: var(--theme__primary--color, $primaryColor);
      border-bottom: none;
    }
    &__current-month,
    &__day-name {
      color: #fff;
    }

    &__day:hover {
      background-color: #f0f0f0;
    }

    &__day--selected,
    &__day--selected:hover {
      background-color: var(--theme__primary--color, $primaryColor);
    }

    &__day--keyboard-selected {
      background-color: transparent;
      color: var(--theme__primary--color, $primaryColor);
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
