$error-color: rgb(255, 87, 87);
$error-color-fade: rgba(255, 102, 102, 0.1);

.container {
  max-width: 500px;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 18px;
  color: rgba(12, 19, 26, 0.7);
}

.form {
  border-radius: 8px;
  box-shadow: 0 0 16px 10px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  display: flex;
  gap: 20px;
  flex-direction: column;

  &.desktop {
    padding: 28px;
  }

  &.mobile {
    padding: 20px;
  }
}

.radioList {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  .radioOption {
    width: 150px;

    &:first-child {
      justify-content: flex-end;
    }

    .radioLabel {
      line-height: 1.3;
    }

    .radioIconChecked {
      border-color: var(--bg-color) !important;
      background-color: var(--bg-color) !important;
    }
  }

  .radioOption ~ .radioOption {
    $gap: 50px;
    position: relative;
    margin-left: $gap;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: calc(-#{$gap} / 2);
      transform: translateY(-50%);
      width: 1px;
      height: calc(100% + 6px);
      background-color: rgba(10, 19, 27, 0.1);
    }
  }
}

.inputContainer {
  display: flex;
  gap: 6px;
  flex-direction: column;

  label {
    margin: 0;
    font-size: 12px;
    color: rgba(12, 19, 26, 0.7);
  }

  input {
    border: none;
    font-size: 16px;
    padding: 12px;
    background-color: #fff;
    border-radius: 8px;
    color: rgba(12, 19, 26, 1);
    box-shadow: 0 0 4px 0 rgba(12, 19, 26, 0.2);

    &::placeholder {
      color: rgba(12, 19, 26, 0.42);
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  span {
    color: $error-color;
    font-size: 12px;

    &::before {
      content: '* ';
    }
  }

  &.error {
    input {
      border: 1px solid $error-color;
      animation: error-prompt 0.3s forwards;

      &::placeholder {
        color: $error-color;
        opacity: 0.7;
      }
    }
  }
}

.confirmBtn {
  border: 0;
  padding: 14px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border-radius: 8px;
  letter-spacing: 0.6px;
  background-color: var(--bg-color);
  cursor: pointer;
  transition: 0.1s transform, 0.3s background-color;

  &:focus,
  &:active {
    outline: none;
  }

  &:not(:disabled):active {
    transform: scale(0.99);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #bbb;
  }
}

// @media (max-width: 400px) {
//   .radioList {
//     flex-direction: column;
//     gap: 16px;

//     .radioOption {
//       justify-content: flex-start !important;
//       margin: 0 !important;
//     }
//   }
// }

@media (max-width: 460px) {
  .title {
    text-align: left;
    font-size: 18px;
  }

  .form {
    &.mobile,
    &.desktop {
      padding: 18px 14px;
    }
  }

  .radioList {
    justify-content: flex-start;
    padding-bottom: 20px;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);

    .radioOption {
      width: unset;

      &::before {
        display: none;
      }
    }

    .radioOption ~ .radioOption {
      margin-left: 40px !important;
    }
  }
}

@keyframes error-prompt {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 transparent;
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 4px 0 $error-color-fade;
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 transparent;
  }
}
