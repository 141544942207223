@mixin padded {
  padding: 20px;
}

@mixin negative-color {
  color: #ad2b2b;
}

@mixin positive-color {
  color: #589d62;
}

@mixin separator($type) {
  border-top: $type 1px #eaf0f9;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
}

.card {
  border-radius: 2px;
  border: solid 1px #eaf0f9;
  background-color: #ffffff;

  &-item {
    border-top: solid 1px #d5dfe8;
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &__title {
      font-size: 14px;
      color: #0c131a;
      font-weight: 600;
    }
    &__price {
      font-size: 14px;
      font-weight: 600;
      color: #0c131a;
      &.positive {
        color: #589d62;
      }
      &.negative {
        color: #ad2b2b;
      }
    }
    &__info {
      display: flex;
      align-items: center;
      gap: 14px;
    }
    &__entity-type {
      padding: 5px 14px;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25;
      &.return {
        // color: #ebab63;
        // background-color: rgba(235, 172, 99, 0.1);
      }
      &.exchange {
        // color: #63c4eb;
        // background-color: rgba(99, 197, 235, 0.1);
      }
    }
    &__quantity {
      font-size: 12px;
      line-height: 1.25;
      color: #0c131a;
    }
    &__arrow-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin-right: 6px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f1f6ff;
      border: 1px solid #f1f6ff;
      transform: rotate(0deg);
      transition: 0.3s;

      &.open {
        transform: rotate(180deg);
      }

      &:hover {
        border-color: #cbd6ec;
      }
    }
  }
}

.loader {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 222;
  max-width: 100% !important;
  background: rgba(255, 255, 255, 0.8);
}

.header {
  @include padded();
}

.title {
  // font-size: 14px;
  // font-weight: 600;
  // margin-bottom: 0px;
  // color: rgba(12, 19, 26, 0.5);
}

.quantity {
  // font-size: 12px;
  // color: #0c131a;
}

.items {
  border-bottom: solid 1px #eaf0f9;
}

.footer {
  padding: 30px 60px;
  // font-size: 16px;
  // color: #0c131a;
  text-align: center;
  // background-color: #edf3ea;

  strong {
    font-weight: 900;
  }
}
