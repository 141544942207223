// @import './order-service-init/modal';
// @import './order-service-init/custom_popup_modal';
// @import './order-service-init/exchange-incentives-modal';
// @import './order-service-details/address-modal';

// @import './order-service-details/store-locator-modal';
// @import './order-service-details/shipping-label-type';
// @import './schedule-pickup';
// @import './cancel-pickup-modal';

.discount__promo__alert,
.offer__notification {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  // border: solid 1px #ffe679;
  // background-color: #fffae7;
  padding: 15px 0px;
  text-align: center;

  @include primary-bg-color-var4;

  // display: flex;
  // align-items: center;

  // &-imageDiv {
  //   padding: 0px 13px;
  // }

  // &-text {
  // font-size: 13px;

  // color: #786631;
  // }

  // & strong {
  //   color: #5c501d;
  // }
}
.offer__notification {
  // INFO - default
  .offer__notification-icon {
    padding: 0 13px;
  }

  .offer__notification-message {
    font-size: 13px;
    color: #786631;
  }

  // DANGER
  &.danger {
    border: solid 1px rgb(255, 179, 179);
    background-color: rgb(255, 221, 221);

    .offer__notification-message {
      color: rgb(121, 49, 49);
    }
  }
}

.discount__promo__alert {
  // margin: 10px 0 0;
}

.order-service-modal-header {
  position: relative;

  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;

    transition: transform 0.2s;
  }

  .close-btn:hover {
    transform: scale(1.2);
  }
}

.order-service-modal {
  #order-service {
    padding-top: 0;

    .review-summary {
      .line-divider {
        margin: 10px 0;
      }
    }
  }
}

#order-service {
  padding-top: 60px;

  // @import './order-service-init/index';
  // @import './order-service-details/index';
  // @import './order-service-review/index';
  @import './order-service-confirmed/index';
  @import './order-service-complete/index';

  .page-action {
    margin: 56px auto 16px;
    display: block;
  }

  .orderService__infoBanner {
    margin: 5px 0;
    display: flex;

    img {
      height: 22px;
    }

    p {
      margin-bottom: 0;
      color: #33475b;
      font-size: 13px;
    }
  }
}
