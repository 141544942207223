$black: #232323;
$buttonColor: #464646;

.ReminderFeedback {
  margin-top: 73px;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__h1 {
    // font-size: 30px;
    // color: $black;
    // font-weight: bold;
    margin-bottom: 2.5rem;
    text-align: center;
  }
  &__image {
    max-width: 300px;
    margin-bottom: 2rem;
    img {
      width: 100%;
    }
  }
  &__description {
    font-size: 18px;
    color: $black;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  &__button {
    background-color: $buttonColor;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 3rem;
    margin-bottom: 3rem;
    transition: opacity 0.2s ease;
    &:hover {
      text-decoration: none;
      color: white;
      opacity: 0.8;
    }
  }
}

@media (max-width: 599px) {
  .ReminderFeedback {
    &__h1 {
      font-size: 24px;
    }
    &__image {
      max-width: 70%;
    }
    &__description {
      font-size: 16px;
    }
  }
}
