.trackingHeader {
  height: 64px;
  background-color: #ffffff;
  text-align: center;
  &__name {
    color: #2c405a;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.5px;
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
}
