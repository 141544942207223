@import './order-name-modal';
@import './auto-login';

#login-col {
  @import './order-name-label';
  @import './form';
  // @media (max-width: 599px) {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
}
