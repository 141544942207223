.container {
  margin: 30px 0;

  .title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .socials {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      a {
        $radius: 8px;
        display: block;
        border-radius: $radius;

        img {
          width: 100%;
          height: 100%;
          border-radius: $radius;
        }
      }
    }
  }

  &.desktop {
    .title {
      font-size: 16px;
    }

    .socials {
      gap: 40px;

      li a {
        width: 36px;
        height: 36px;
      }
    }
  }

  &.mobile {
    .title {
      font-size: 14px;
    }

    .socials {
      gap: 50px;

      li a {
        width: 32px;
        height: 32px;
      }
    }
  }
}
