.container {
  height: 100dvh;
  padding: 24px;
  max-width: 1000px;
  margin: auto;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;

  & > div {
    flex: 1;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h1 {
    color: #45505b;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
  }

  p {
    color: #637381;
    font-size: 16px;
    line-height: 23.232px;
  }

  span {
    color: #006fbb;
    text-decoration-line: underline;
  }
}

.image {
  height: 100px;
  position: relative;

  img {
    z-index: -1;
    position: absolute;
    top: 60%;
    right: 0;
    transform: translate(50px, -50%);
  }
}

@media (max-width: 982px) {
  .content {
    h1 {
      font-size: 36px;
    }
    p {
      font-size: 14px;
    }
  }
  .image img {
    transform: translate(100px, -50%) scale(0.7);
  }
}

@media (max-width: 730px) {
  .container {
    gap: 0;
    max-width: unset;
  }

  .content {
    flex: unset;
    text-align: center;
  }
  .image {
    display: none;
  }
}
