@import './checkbox';
@import './radio';

.uiKit__element {
  label {
    font-size: 12px;
    line-height: 12px;
    color: #607180;
    margin-bottom: 5px;
  }

  .uiKit__element--labelWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    label {
      font-size: 12px;
      line-height: 12px;
      color: #607180;
      margin-bottom: 0;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 12px;
      line-height: 1.08;
      color: #a6afb8;
    }
  }
}
