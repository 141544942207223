@import './../../mixins';
@import './../../variables';

$padding: 40px;

.OrderTrack {
  @media (max-width: 767px) {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  &--request {
    max-width: 975px;
    margin-right: auto;
    margin-left: auto;
    .h3 {
      padding-top: 1.5rem;
      margin-left: 1.5rem;
      margin-bottom: 1.5rem !important;
    }
    .OrderTrack__serviceWrapper {
      margin-left: 1.5rem;
    }
    .OrderTrack__serviceNumber {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
  &__totalPrice {
    color: #212837;
    font-size: 19px;
  }

  &__box {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px $boxBorderColor;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      border-radius: 0;
    }
  }
  &__boxTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 1.5rem;
    border-bottom: 1px solid #d1e0f0;
    @media (max-width: 767px) {
      padding: 1rem 1.25rem;
    }
  }
  .h2,
  .DeliveryStatus {
    font-size: 18px;
    line-height: 19px;
    color: #2a2f3b;
    font-weight: bold;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 1rem;
      line-height: 1.25;
    }
  }
  .button {
    color: white;
    background-color: var(--theme__primary--color, $primaryColor);
    box-shadow: none;
    border: 1px solid var(--theme__primary--color, $primaryColor);
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 156px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
  .outline-button {
    color: var(--theme__primary--color, $primaryColor);
    background-color: transparent;
    box-shadow: none;
    border: 1px solid var(--theme__primary--color, $primaryColor);
    font-size: 14px;
    display: inline-block;
    line-height: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 156px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      color: white;
      background-color: var(--theme__primary--color, $primaryColor);
    }
  }
  &__item {
    display: flex;
    padding: 33px 1.5rem 1rem;
    border-bottom: 1px solid #eaf1f8;
    margin-bottom: 21px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
      padding: 1.25rem 1rem;
      margin-bottom: 0;
    }
  }
  &__image {
    margin-right: 1.5rem;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 767px) {
      margin-right: 1rem;
    }
  }
  &__itemName {
    color: #2a2f3b;
    font-size: 1rem;
    line-height: 1.25;
    font-weight: 500;
  }
  &__itemSubtitle {
    color: #595f6e;
    font-size: 14px;
    line-height: 24px;
  }
  &__itemPrice {
    color: #595f6e;
    font-size: 14px;
    line-height: 24px;
  }
  &__serviceWrapper {
    margin-left: 8.5rem;
    padding-right: 1.5rem;
    border-bottom: 1px solid #eaf1f8;
    margin-right: 1.5rem;
    padding-bottom: 2rem;
    @media (max-width: 1023px) {
      margin-right: 0;
    }
    @media (max-width: 767px) {
      margin-top: 1rem;
      padding-left: 6rem;
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 1.5rem;
    }
    @media (max-width: 479px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  &__serviceTop + .ExchangeItemCard {
    margin-top: 1.5rem;
  }
  &__serviceTopMain {
    @media (max-width: 576px) {
      margin-bottom: 1rem;
    }
  }

  // If Fulfillment Item is not showing, need to add enough padding on top
  &__boxTop + &__serviceWrapper {
    padding-top: 33px;
  }

  .h3 {
    font-size: 20px;
    color: var(--theme__primary--color, $primaryColor);
    margin-bottom: 11px;
  }
  &__serviceDescription {
    // font-size: 14px;
    // line-height: 20px;
    // color: #2a2f3b;
    // margin-bottom: 0;
  }
  &__serviceTextExtra {
    font-size: 13px;
    line-height: 20px;
    color: #7c98b6;
  }
  &__serviceNumber {
    font-weight: 500;
    font-size: 20px;
  }
  .text-green {
    color: $green !important;
  }
  .text-black {
    color: $black !important;
  }
  .text-regular {
    font-weight: 400 !important;
  }
  .text-yellow {
    color: $yellow !important;
  }
  .text-red {
    color: $red !important;
  }
  .fw-medium {
    font-weight: 500 !important;
  }
  &__missingInformation {
    font-size: 14px;
    line-height: 20px;
    color: #2a2f3b;
  }
  &__item + &__shippingGroups {
    padding-top: 0.5rem;
    @media (max-width: 767px) {
      padding-top: 1.5rem;
    }
  }
  .ExchangeItemCard + &__shippingGroups {
    @media (max-width: 767px) {
      padding-top: 1.25rem;
    }
  }
  &__shippingGroups {
    display: flex;
    padding-top: 26px;
    padding-bottom: 58px;
    margin-left: 8.5rem;
    margin-right: 1rem;
    max-width: 800px;
    @media (max-width: 767px) {
      margin-left: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #eaf1f8;
      margin-bottom: 1.5rem;
    }
  }
  &__shippingGroup {
    padding-right: 1rem;
    padding-bottom: 1rem;
    @media (max-width: 479px) {
      width: 100%;
      flex-basis: 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__shippingKey {
    color: #8c97b4;
    font-size: 12px;
    margin-bottom: 4px;
  }
  &__shippingValue {
    color: #2a2f3b;
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
  }
  &__fulfillmentStatuses {
    display: flex;
    margin-left: 8rem;
    margin-right: 55px;
    padding-bottom: 58px;
    border-bottom: 1px solid #eaf1f8;
    margin-bottom: 2rem;
    @media (max-width: 1023px) {
      flex-direction: column;
      margin-left: 1rem;
      margin-right: 1rem;
      align-items: center;
      padding-bottom: 1.5rem;
      margin-bottom: 0;
    }
  }
  &__timeline {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      left: calc(22.5px);
      top: 0;
      bottom: 0;
      border-left: 2px solid #e5ecf3;
    }
    @media (max-width: 1023px) {
      margin-bottom: 1.5rem;
    }
    @media (max-width: 479px) {
      width: 100%;
    }
  }
  &__timelineItem {
    display: flex;
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__timelineItem:first-child &__timelineItemValue::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 50%;
    border-left: 6px solid white;
    left: -28px;
    z-index: 1;
  }
  &__timelineItem:last-child &__timelineItemValue::after {
    content: '';
    position: absolute;
    top: 50%;
    bottom: 0;
    border-left: 6px solid white;
    left: -28px;
    z-index: 1;
  }
  &__timelineItemKey {
    font-size: 13px;
    font-weight: 500;
    color: #2a2f3b;
    width: 80px;
    text-align: right;
    flex-shrink: 0;
  }
  &__time {
    color: #595f6e;
    font-weight: 400;
  }
  &__timelineItemValue {
    font-size: 13px;
    font-weight: 500;
    color: #2a2f3b;
    margin-left: 48px;
    padding-left: 1rem;
    position: relative;
    flex-shrink: 1;
    &::before {
      content: '';
      position: absolute;
      left: -33px;
      top: calc(50% - 0.5rem);
      width: 1rem;
      height: 1rem;
      background-color: #fff;
      background-image: url('../images/icons/timeline-done.svg');
      background-size: 1rem 1rem;
      // border-radius: 50%;
      z-index: 2;
    }
  }
  &__timelineTimestamp {
    color: #8c97b4;
    font-size: 12px;
  }
  &__map {
    width: 462px;
    height: 274px;
    margin-left: auto;
    flex-shrink: 0;
    border-radius: 10px;
    overflow: hidden;
    @media (max-width: 1023px) {
      margin-right: auto;
      width: 100%;
      max-width: 800px;
    }
    .mapboxgl-map.mapboxgl-map.mapboxgl-map.mapboxgl-map {
      max-height: none !important; // app.scss has a global of 200px on mobile viewport. That shouldn't be there. Using over-achieving selector as an override
    }
    @media (max-width: 479px) {
      max-height: 220px;
    }
  }
  &__bottom {
    &:empty {
      display: none;
    }
    margin-left: 8rem;
    padding-bottom: 51px;
    margin-top: 1.5rem;
    @media (max-width: 1023px) {
      padding-bottom: 2rem;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  &__summary {
    font-size: 18px;
    color: #595f6e;
    margin-bottom: 10px;
    line-height: 1;
    a {
      color: #2a2f3b;
      text-decoration: underline;
    }
    @media (max-width: 1023px) {
      line-height: 1.5;
    }
    @media (min-width: 768px) {
      br {
        display: none;
      }
    }
  }
  &__refundAmount {
    font-size: 14px;
    white-space: pre-line;
  }
  &__paymentButton {
    margin-top: 0.5rem;
  }
  &__shippingLabelButton {
    margin-top: 0.5rem;
  }
  &__total {
    padding: 1.5rem;
  }
  &__totalHeading {
    font-size: 18px;
    line-height: 1.33;
    color: #001234;
    padding-bottom: 1.12rem;
  }
  &__shippingHeading {
    font-size: 18px;
    padding: 1rem;
    line-height: 1.33;
    color: #001234;
    border-bottom: 1px solid #cbd6e2;
  }
  .line-divider {
    height: 2px;
    border: 1px dotted #cbd6e2;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  .sub-total-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.85;
    letter-spacing: 0.9px;
    color: #8c97b4;
    &.final {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.85;
      letter-spacing: 0.9px;
      color: #0012348c;
    }
  }
  .sub-total-price {
    // font-size: 18px;
    // line-height: 1.33;
    // text-align: right;
    // color: #001234;
    &.final-price {
      // font-size: 18px;
      // font-weight: bold;
      // line-height: 1.33;
      // text-align: right;
      color: var(--theme__primary--color, $primaryColor);
    }
  }
}

.ExchangeItemCard {
  display: flex;
  @media (min-width: 480px) and (max-width: 1023px) {
    margin-left: -5.5rem;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
  &__left,
  &__right {
    width: 40%;
    display: flex;
    @media (max-width: 1023px) {
      flex-grow: 1;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  &__image {
    margin-right: 1.5rem;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 767px) {
      margin-right: 1rem;
    }
  }
  &__title {
    color: #2a2f3b;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
  }
  &__subtitle {
    color: #516f90;
    font-size: 14px;
    line-height: 24px;
  }
  &__icon {
    color: $green;
    width: 24px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    font-size: 1.25rem;
    margin-left: 1rem;
    margin-right: 1rem;
    flex-shrink: 0;
    @media (max-width: 767px) {
      transform: rotate(90deg);
      padding-bottom: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
  }
}
