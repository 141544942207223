.shipment-tracking--notificationBanner {
  * {
    margin: 0;
    padding: 0;
  }

  height: 80px;
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  font-size: 13px;
  color: #ffffff;
  background-color: #181818;
  padding: 0 30px;
  z-index: 10;
  overflow: hidden;
  display: flex;
  align-items: center;
  animation: appear 1s forwards ease-in;

  &--wrapper {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__info {
    display: flex;
    align-items: center;

    &--icon {
      margin-right: 40px;

      svg {
        width: 35px;
        height: 44px;
        animation: virus-glide 3s infinite, pulse 1s infinite;
        path {
          fill: #fff;
        }
      }
    }

    &--description {
      max-width: 800px;
      font-size: 24px;
      font-weight: bold;
    }
  }

  &__action {
    margin-left: 80px;
    button {
      padding: 0 40px;
      height: 40px;
      border-radius: 20px;
      font-size: 18px;
      font-weight: bold;
      color: #181818;
      background-color: #fff;
      cursor: pointer;
      border: none;
      white-space: nowrap;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }

  @media (min-width: 1200px) {
    height: 80px;

    &--wrapper {
      max-width: 1440px - 60px;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    &__info {
      &--description {
        font-size: 20px;
      }
    }
  }
  @media (min-width: 601px) and (max-width: 767px) {
    height: auto;
    &__info {
      &--description {
        font-size: 20px;
      }
    }
  }
  @media (max-width: 600px) {
    height: auto;
    padding: 25px 15px;

    &--wrapper {
      flex-direction: column;
    }

    &__info {
      position: relative;
      margin-bottom: 20px;

      &--icon {
        position: absolute;
        margin-right: 0;
      }

      &--description {
        padding: 0 45px;
        font-size: 16px;
        text-align: center;
      }
    }

    &__action {
      margin: 0;
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes virus-glide {
  0% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
