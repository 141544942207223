@mixin grid-view {
  .product {
    $y-gap: 0px;
    $x-gap: 10px;

    height: 160px;
    margin: $y-gap $x-gap;
    width: calc((100% - #{$x-gap} * 4) / 3);

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-child(2) {
      margin-bottom: 28px;
    }

    .imageContainer {
      height: calc(160px - #{$x-gap} - 14px);
    }
  }
}

@mixin spread-view {
  width: 100%;

  .product {
    $gap: 10px;
    height: 140px;
    margin: 0 $gap;
    width: calc((100% - #{$gap} * 10) / 6);

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    .imageContainer {
      height: calc(140px - #{$gap} - 14px);
    }
  }
}

.container {
  display: flex;
  flex-direction: column;

  &.fullWide {
    width: 100%;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 18px;
  color: rgba(12, 19, 26, 0.7);
}

.products {
  margin: 0;
  padding: 0;
  list-style: none;
  flex-grow: 1;

  &.grid {
    @include grid-view;
  }

  &.spread {
    @include spread-view;
  }

  &.mobile {
    @include grid-view;

    $height: 130px;
    $gap: 10px;

    .product {
      height: $height !important;

      .imageContainer {
        height: calc(#{$height} - #{$gap} - 14px) !important;
      }
    }
  }
}

.product {
  display: inline-block;

  a {
    gap: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      // .imageContainer img {
      //   transform: scale(1.2);
      // }
      .productName {
        color: rgba(12, 19, 26, 1);
        text-decoration: underline;
      }
    }
  }

  .imageContainer {
    flex-grow: 1;
    background-color: rgb(233, 233, 233);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.2s;
    }
  }

  .productName {
    font-size: 12px;
    font-weight: bold;
    color: rgba(12, 19, 26, 0.7);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 14px;
  }
}

@media screen and (max-width: 700px) {
  .title {
    font-size: 14px;
  }

  .products {
    &.spread {
      @include grid-view;
    }
  }
}
