$max-size: 659px;

#confirmed {
  .content-wrap {
    background: #fff;
    border-radius: 6px;
    // border: solid 1px #ecf1f4;
    // padding: 30px 15px;
    margin-top: 30px;
    height: 500px;
    overflow-y: auto;
  }
  .ConfirmedHeader {
    // border-bottom: 2px dashed #cbd6e2;
    // margin-bottom: 22px;
  }
  .page-lead {
    box-shadow: inset 0 -1px 0 0 #ecf3fb;
    padding-bottom: 20px;
    // p {
    //   font-size: 18px;
    //   line-height: 1.44;
    //   color: #33475b;
    //   margin-bottom: 0px;
    // }
  }
  .exchange-item-alert {
    // p {
    //   font-size: 12px;
    // }
    a {
      color: #5a5bff;
    }
  }
  #labels {
    margin-top: 20px;
    h6 {
      margin-bottom: 20px;
      margin-top: 30px;
    }
    .product-row {
      padding-top: 16px;
      padding-bottom: 20px;
      box-shadow: inset 0 -1px 0 0 #ecf3fb;
      img {
        width: 100%;
      }
      .product-details {
        padding-left: 15px;
        // .service-text {
        //   font-size: 16px;
        //   font-weight: 500;
        //   line-height: 1.25;
        //   color: #001234;
        // }
        // .product-name {
        // font-size: 14px;
        // line-height: 1.8;
        // color: #2a2f3b;
        // }
        // .product-price {
        // font-size: 14px;
        // line-height: 1.43;
        // color: #516f90;
        // }
        .status {
          // font-size: 14px;
          // line-height: 2;
          color: $green;
        }

        .product-review {
          font-size: 14px;
          line-height: 1.43;
          color: #001234;
          &.active {
            font-size: 14px;
            line-height: 1.43;
            color: var(--theme__primary--color, $green);
          }
        }
      }
      .message {
        font-size: 13px;
        line-height: 1.46;
        color: #7c98b6;
        text-align: right;
      }
      .download-button {
        border-radius: 5px;
        background-color: #5a5bff;

        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: #ffffff;
      }
    }
  }

  .noSlotAvailable {
    font-size: 14px;
    // margin-top: 20px;
    padding: 10px 15px;
    // background-color: #fff7d5;
    // color: #867220;
    // border-radius: 5px;
    @include primary-bg-color-var3;
    @include br-4;

    a {
      color: black;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .successfulPickupInfo {
    font-size: 14px;
    padding: 10px 15px;
    margin-bottom: 20px;
    background-color: #fff7d5;
    color: #867220;
    border-radius: 5px;

    strong {
      font-weight: 700;
    }
  }

  @media (max-width: $max-size) {
    .content-wrap {
      background: #fff;
      border-radius: 6px;
      // border: solid 1px #ecf1f4;
      padding: 0px;
      // margin: 10px;
      height: auto;
    }
    .page-lead {
      // border-bottom: 2px dashed #cbd6e2;

      p {
        font-size: 14px;
        // font-weight: normal;
        // font-style: normal;
        // font-stretch: normal;
        // line-height: 1.43;
        // letter-spacing: normal;
        // color: #33475b;
      }
      .track-button {
        margin-top: 10px;
        width: 100%;
        margin-bottom: 10px;
        margin-left: 0px !important;
      }
    }
    #labels {
      margin-top: 20px;
      .product-row {
        .product-details {
          // .service-text {
          //   font-size: 13px !important;
          //   font-weight: 500;
          //   line-height: 1.25;
          //   color: #001234;
          // }
          .product-name {
            font-size: 13px !important;
          }
          .status {
            font-size: 13px !important;
          }
        }
        .message {
          text-align: center;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
  .track-button-row {
    // margin-top: 22px;

    &.pickup-enabled {
      & .pickup-link {
        margin-right: 10px;
      }
    }

    // & button.track-request {
    // }

    & button.schedule {
      position: relative;
      min-width: 205px;
      padding: 9px 0px;

      &:disabled {
        opacity: 0.6;
        // cursor: progress;
      }

      .fa-spinner {
        margin-left: 8px;
      }
    }
    // box-shadow: inset 0 -1px 0 0 #ecf3fb;
    .track-button {
      // width: 280px;
      // border: 1px solid #5a5bff;
      // border-radius: var(--theme__login-input--border-radius, 4px);
      // background: #fff;
      font-size: 14px;
      font-weight: 500;
      // color: #5a5bff;
      &-disabled {
        background-color: #f5f8fa !important;
      }
      @media (max-width: 1023px) {
        max-width: calc(100% - 50px);
      }
      @media (max-width: 575px) {
        margin-top: 20px;
        text-align: center;
      }
    }
    .self-center {
      align-self: center;
    }
    p {
      font-size: 12px;
      line-height: 1.5;
      // color: #516f90;
      margin-bottom: 16px;
      margin-top: 22px;
    }
    @media (max-width: 575px) {
      text-align: center;
    }
  }
  .warning-text {
    // color: #001234;
    margin-bottom: 1rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    // strong {
    //   font-weight: bold;
    //   color: var(--theme__primary--color, $primaryColor);
    // }
    @include primary-bg-color-var4;
  }
  .labels-row {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    // margin-top: 22px;
    // box-shadow: inset 0 -1px 0 0 #ecf3fb;

    a {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      text-align: center;
      text-decoration: none;
      padding: 9px 0px;
      // margin-bottom: 20px;
      color: var(--theme__primary-btn--color, #ffffff);
      background-color: var(
        --theme__primary-btn--background-color,
        $primaryColor
      );
      border-width: var(--theme__primary-btn--border-width, 1px);
      border-style: var(--theme__primary-btn--border-style, solid);
      border-color: var(--theme__primary-btn--border-color, $primaryColor);
      border-radius: var(--theme__primary-btn--border-radius, 4px);
      &:active,
      &:focus {
        outline: none;
      }
    }
    .icon-relative {
      position: relative;
      left: 5px;
    }
    button.track-button-disabled {
      outline: none;
      box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.1);
    }
    .track-button-disabled {
      border-radius: 5px;
      color: #ffffff;
      padding: 9px 0px;
      cursor: not-allowed;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      // margin-bottom: 20px;
      border-radius: 6px;
      background-color: #f5f8fa !important;
      border: 1px solid #f5f8fa;
      color: #7c98b6;
    }
    .track-button-loading {
      cursor: progress;
      border-radius: var(--theme__primary-btn--border-radius, 4px);
    }
    .download-button {
      min-width: 205px;
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (max-width: 575px) {
      display: flex;
      flex-direction: column;
      // align-items: center;
    }
  }

  .scheduled-store-info {
    line-height: 1.44;
    color: #33475b;
    margin: 22px 0;

    h5 {
      font-size: 18px;
      font-weight: 700;
    }

    div,
    p {
      font-size: 14px;
    }

    strong {
      font-weight: 700;
    }
  }
}
