@import '../variables';

.Footer {
  // color: $textLight;
  // font-size: 0.75rem;
  // line-height: 1.5;
  // font-weight: 500;
  margin-bottom: 56px;
  margin-top: 40px;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  a,
  span {
    // color: $black;
    color: inherit;
    font-weight: bold;
  }
  a {
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
  }
}
