// $backgroundColor: #aebed0;
// $backgroundColor: #eee;
$backgroundColor: rgba(13, 27, 36, 0.5);

.OrderNameLabel {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  color: white;
  // font-size: 24px;
  // border-radius: var(--theme__login-input--border-radius, 4px);
  // background-color: $backgroundColor;

  background-color: inherit;
  font-weight: normal;
  flex-shrink: 0;
  padding: 0 2px;
  box-shadow: none;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  svg {
    path {
      fill: var(--theme__primary--color, $backgroundColor);
      transition: 0.1s;
    }

    &:hover path {
      fill: var(--theme__primary--color-variant1, $backgroundColor);
    }
  }
}
