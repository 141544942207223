.auto-login {
  position: fixed;
  left: 0;
  top: 71px;
  width: 100vw;
  min-height: calc(100vh - 71px);
  background: #fff;
  z-index: 1000;

  &__content {
    padding: 74px 48px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--loader {
      margin: 0;
    }

    &--msg {
      margin: 48px 0 24px 0;
      font-size: 14px;
      line-height: 2.07;
      color: #0c131a;
      text-align: center;
    }

    &--force-button {
      border: 0;
      padding: 0;
      outline: 0;
      background: none;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
