.react-select-container {
  .react-select {
    &__control {
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid #c6dae8;
      padding: 0;
      box-shadow: none;

      &:hover {
        border-bottom-color: hsl(0, 0%, 70%);
      }

      &--menu-is-open,
      &--menu-is-open:hover,
      &:focus,
      &:active {
        box-shadow: none;
        border-bottom-color: var(--theme__primary--color, $primaryColor);
      }
    }
    &__value {
      &-container {
        padding: 0px;
      }
    }
    &__menu {
      margin-top: 0px;
      border-radius: 0px;
      z-index: 10000;
    }

    &__indicator {
      &-separator {
        display: none;
      }
    }
    &__menu-list {
      max-height: 120px;
      z-index: 1000;
    }
    &__option--is-selected {
      color: #fff;
      background-color: var(--theme__primary--color, $primaryColor) !important;
    }
    &__option--is-focused,
    &__option--is-focused:active {
      background-color: transparent;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--theme__primary--color, $primaryColor);
        opacity: 0.2;
      }
    }
  }
}
