.covid-instructions {
  $padd-Y: 60px;
  $padd-X: 70px;
  padding: $padd-Y $padd-X;
  background-color: #ffffff;
  animation: appear 1s forwards;

  &__grid {
    display: flex;

    &--column {
      width: 50%;
    }
  }

  &__header {
    min-height: calc(100vh - 64px - #{$padd-Y * 2});
    align-items: center;

    &--brandingInfo {
      &__description {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          margin-bottom: 20px;
          max-width: 400px;
          font-size: 16px;
          line-height: 1.44;
          color: #181818;
        }
      }
    }

    &--logisticsInfo {
      $card-gap: 35px;
      padding: 0;
      margin: 0;

      &__card {
        display: inline-block;
        width: calc((100% - #{$card-gap}) / 2);
        height: 150px;
        border-radius: 24px;
        background-color: #ffffff;
        box-shadow: 0 0 8px 0 rgba(90, 91, 255, 0.1);
        overflow: hidden;
        transition: 0.2s;

        &:nth-child(2n + 1) {
          margin: $card-gap $card-gap 0 0;
        }
        &:first-child {
          margin-top: 0;
        }

        &:hover {
          box-shadow: 0 0 8px 0 rgba(90, 91, 255, 0.3);
        }

        &--link {
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          &:hover {
            text-decoration: none;
          }
        }

        &--logo {
          flex: 1;
          margin: 0 auto;

          &.WHO {
            fill: #009cde;
            transform: scale(0.55);
          }

          &.UPS {
            width: 50px;
            height: 50px;
          }

          &.USPS {
            width: 100px;
            transform: scale(2);

            path:first-of-type {
              fill: transparent;
            }
          }

          &.FedEx {
            width: 130px;
          }
        }

        &--title {
          position: relative;
          font-size: 14px;
          font-weight: 600;
          color: #181818;
          line-height: 35px;
          text-align: center;
          background-color: rgba(222, 222, 255, 0.5);
        }
      }
    }
  }

  &__faqs {
    margin-top: 75px;
    border-top: 1px solid #dedeff;
    border-bottom: 1px solid #dedeff;
    padding: 75px 0;
    $gap: 80px;

    .covid-instructions__grid {
      $overlap-shift: -60px;
      margin: $overlap-shift 0;

      &:first-of-type,
      &:last-of-type {
        margin: 0;
      }

      &--column {
        &:first-child {
          margin-right: $gap;
        }

        &:last-child {
          margin-left: $gap;
        }
      }
    }

    &--title {
      h1 {
        font-size: 54px;
        font-weight: bold;
        color: #181818;
        max-width: 320px;
      }
      p {
        font-size: 18px;
        line-height: 1.44;
        color: #181818;
        max-width: 530px;
      }
    }

    &--visualSymbol {
      position: relative;

      .faded-visual {
        position: absolute;
        top: 50%;
        left: 50%;
        img {
          transform: translate(-50%, -50%) scale(2.1);
          opacity: 0.1;
        }
      }
    }

    &--infoCard {
      border-radius: 24px;
      box-shadow: 0 0 8px 0 rgba(90, 91, 255, 0.1);
      background-color: #ffffff;
      padding: 60px 70px;
      color: #181818;

      &__img {
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        img {
          margin: 0 auto;
        }
      }

      &__title {
        font-size: 30px;
        font-weight: 600;
      }

      &__description {
        font-size: 17px;
        line-height: 1.47;
        margin-top: 30px;
      }

      &__link {
        margin-top: 30px;
        border: solid 1px #5a5bff;
        border-radius: 200px;
        background-color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        color: #5a5bff;
        padding: 12px 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:focus,
        &:active {
          outline: none;
        }

        &:hover {
          color: #5a5bff;
          text-decoration: none;
        }
      }
    }
  }

  // @media (min-width: 768px) and (max-width: 992px) {
  @media (max-width: 1050px) {
    padding: 30px;

    &__grid {
      flex-direction: column;

      &--column {
        width: 100%;
      }
    }

    &__header {
      min-height: auto;

      &--brandingInfo {
        text-align: center;
        margin-bottom: 20px;

        &__description {
          max-width: 800px;
          margin: 20px auto;
          align-items: center;

          p {
            max-width: 600px;
          }
        }

        &__icon {
          svg {
            width: 300px;
          }
        }
      }
    }

    &__faqs {
      margin-top: 60px;
      padding: 60px 0;

      .covid-instructions__grid {
        margin: 0;

        &--column {
          &:first-child {
            margin-right: 0;
          }

          &:last-child {
            margin-left: 0;
          }
        }
      }

      .covid-instructions__grid ~ .covid-instructions__grid {
        margin-top: 35px;
      }

      &--title {
        h1 {
          font-size: 24px;
        }
        p {
          font-size: 16px;
          margin-bottom: 25px;
        }
      }

      &--visualSymbol {
        .faded-visual {
          display: none;
        }
      }
    }
  }
  // @media (min-width: 601px) and (max-width: 767px) {
  @media (max-width: 767px) {
    &__header {
      &--brandingInfo {
        &__icon {
          svg {
            width: 200px;
          }
        }
      }
    }

    &__faqs {
      margin-top: 40px;
      padding: 40px 0;

      &--title {
        h1 {
          font-size: 20px;
        }
        p {
          font-size: 14px;
          margin-bottom: 25px;
        }
      }

      &--infoCard {
        padding: 26px 30px;
        text-align: center;

        &__img {
          margin-bottom: 26px;
          img {
            width: 46px;
            max-height: 60px;
          }
        }

        &__title {
          font-size: 18px;
        }

        &__description {
          font-size: 14px;
        }

        &__link {
          display: block;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 600px) {
    &__header {
      &--brandingInfo {
        &__description {
          margin-top: 10px;
          font-size: 14px;
        }
      }

      &--logisticsInfo {
        &__card {
          $card-gap: 10px;
          height: 100px;
          border-radius: 8px;
          width: calc((100% - #{$card-gap}) / 2);

          &--logo {
            width: 50%;

            &.WHO {
              fill: #009cde;
              transform: scale(1.5);
            }

            &.DHL {
              transform: scale(1.4);
            }

            &.UPS {
              width: 40px;
              height: 40px;
            }

            &.USPS {
              width: 100px;
              transform: scale(1.8);

              path:first-of-type {
                fill: transparent;
              }
            }

            &.FedEx {
              width: 100px;
            }
          }

          &--title {
            height: 24px;
            line-height: 24px;
          }

          &:nth-child(2n + 1) {
            margin: $card-gap $card-gap 0 0;
          }
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    &__faqs {
      margin-top: 30px;
      padding: 30px 0;
    }
  }
}

.covid-disclaimer {
  padding: 0 1.5rem;
  align-items: center;
  color: #a0a0a0;
  font-size: 12px;
  margin-bottom: 84px;

  p {
    text-align: center;
    max-width: 800px;
    margin: auto;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
