.imageContainer {
  opacity: 0;
  animation: glide-down 1.5s forwards;

  .image {
    height: 300px;
    width: unset;
  }
}

.message {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .text {
    margin: 0;
    opacity: 0;
    font-size: 16px;
    line-height: 24px;
    color: rgba(12, 19, 26, 0.8);
    animation: glide-up 0.3s forwards;

    &:nth-of-type(1) {
      margin-bottom: 8px;
      animation: glide-up 0.3s 0.3s forwards;
    }
    &:nth-of-type(2) {
      animation: glide-up 0.3s 0.6s forwards;
    }
  }

  strong {
    color: #0c131a;
  }
}

.button {
  margin-top: 12px;
  font-size: 16px;
  background-color: #0c131a;
  border: none;
  opacity: 0;
  animation: glide-up 0.3s 0.9s forwards;
}

@keyframes glide-down {
  from {
    opacity: 0;
    transform: scale(1.1) rotate(3deg) translateY(-20px);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(0) translateY(0);
  }
}

@keyframes glide-up {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
