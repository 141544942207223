
.Container {
  text-align: center;
  position: relative;
  margin-top: 10%;
}
.MainTitle {
  display: block;
  font-size: 2rem;
  font-weight: lighter;
  text-align: center;
}
.MainDescription {
  max-width: 50%;
  font-size: 1.2rem;
  font-weight: lighter;
}
.MainGraphic {
  position: relative;
}
.Cog {
  width: 10rem;
  height: 10rem;
  fill: #6aafe6;
  transition: easeInOutQuint();
  animation: CogAnimation 5s infinite;
}
.Spanner {
  position: absolute;
  transform: rotate(20deg);
  top: 10%;
  left: 20%;
  width: 10rem;
  height: 10rem;
  fill: #8ec0e4;
  animation: SpannerAnimation 4s infinite;
}
.Hummingbird {
  position: absolute;
  width: 3rem;
  height: 3rem;
  fill: #30a9de;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media(max-width: 640px){
  .Cog {
  width: 7rem;
  }
  .Hummingbird{
    width: 2rem;
  }
}
@keyframes CogAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes SpannerAnimation {
  0% {
    transform: translate3d(20px, 20px, 1px) rotate(0deg);
  }
  10% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(180deg);
  }
  15% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(360deg);
  }
  20% {
    transform: translate3d(1000px, -1000px, 1px) rotate(180deg);
  }
  30% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(360deg);
  }
  40% {
    transform: translate3d(1000px, -1000px, 1px) rotate(360deg);
  }
  50% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(180deg);
  }
  100% {
    transform: translate3d(0, 0px, 0px) rotate(360deg);
  }
}
