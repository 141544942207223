$textColor: #ad2b2b;

&__cancelRequest {
  text-align: center;
}
&__cancelRequestButton {
  box-shadow: none;
  border: 0;
  color: $textColor;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: #d53030;
  }
  &:focus {
    outline: none;
  }
}
