.customToast {
  border-radius: 5px;
  box-shadow: 0 12px 24px 0 rgba(20, 21, 58, 0.32);
  background-color: #0c1013 !important;
  bottom: 0px;
  &__progress {
    &-bar {
      background: rgba(255, 255, 255, 0.5) !important;
    }
  }
  &__screen {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &-text {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #ffffff;
    }
    img {
      margin-right: 10px;
    }
  }
  &__body {
    & + .Toastify__close-button--default {
      color: #fff;
      opacity: 1;
    }
  }
}
