// Decent variable names
$primaryColor: #5a5bff;
$black: #001234;
$boxBorderColor: #ecf1f4;
$borderRadiusBase: 5px;
$green: #01ac26;
$red: rgba(255, 126, 78, 0.7);
$yellow: #ffbb00;

// Shaky variable names
$textLight: #516f90;
$textColor: #33475b;
$hoverWhite: #fafbfc;
$tableBorderColor: #f5f8fa;

