.pickupScheduler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  margin-bottom: 30px;
  border-radius: 4px;
  padding: 20px 1.5rem;
  box-shadow: 0 0 0 1px #ecf1f4;
  // background-color: var(--theme__primary--color-variant4);
  border: 1px solid
    var(--theme__primary--color-variant3, rgba(255, 88, 62, 0.25));

  &__appointmentInfo {
    display: flex;
    align-items: center;
    padding-right: 50px;

    &--icon {
      width: 40px;
      height: 40px;
      margin-right: 20px;

      & > g {
        path,
        polygon {
          fill: var(--theme__primary--color);
        }
      }
    }

    &--brief {
      font-size: 14px;
      text-align: left;
    }

    strong {
      font-weight: 700;
    }
  }

  &__actions {
    button {
      $y-offset: 3px;
      $x-offset: 10px;
      white-space: nowrap;
      margin: 0;
      padding: $y-offset $x-offset;
      border: none;
      font-size: 15px;
      color: var(--theme__primary--color, $primaryColor);
      background-color: transparent;
      position: relative;
      cursor: pointer;
      transition: 0.15s;

      &:focus,
      &:active {
        outline: none;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 1.5 * $y-offset;
        left: $x-offset;
        width: calc(100% - (2 * #{$x-offset}));
        height: 1px;
        opacity: 0.6;
        background-color: var(--theme__primary--color, $primaryColor);
        transition: 0.15s;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    button ~ button {
      margin-left: 30px;
    }
  }

  @media (max-width: 767px) {
    border: none;
    height: auto;
    border-radius: 0;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 20px;

    &__appointmentInfo {
      padding: 0;

      &--icon {
        display: none;
      }
      &--brief {
        text-align: center;
      }
    }

    &__actions {
      width: 100%;
      margin-top: 10px;
      text-align: center;
      button {
        padding: 5px 0;
        &::before {
          width: 100%;
          left: 0;
        }
      }
    }
  }
}
