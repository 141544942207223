.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.banner {
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(20px) opacity(0.9);
  z-index: -1;
}

.bannerImg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
