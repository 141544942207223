// // CSS variables

// @font-face {
//   font-family: p22-underground-regular;
//   src: url(https://res.cloudinary.com/supply-ai/raw/upload/v1605781785/retailers/rowing-blazzers/fonts/P22_Underground_Regular.otf);
// }

// @font-face {
//   font-family: p22-underground-medium;
//   src: url(https://res.cloudinary.com/supply-ai/raw/upload/v1605781599/retailers/rowing-blazzers/fonts/P22_Underground_Medium.otf);
// }

// :root {
//   // Rowing Blazers
//   --theme__font-family: "p22-underground-regular", sans-serif;

//   --theme__header-link--font-size: 13px;
//   --theme__header-link--font-weight: 400;
//   --theme__header-link--color: #000000;
//   --theme__header-link--font-family: "p22-underground-regular", sans-serif;

//   --theme__login-header--font-size: 20px;
//   --theme__login-header--font-weight: 500;
//   --theme__login-header--color: #000000;
//   --theme__login-header--font-family: "p22-underground-medium", sans-serif;

//   --theme__login-label--font-size: 12px;
//   --theme__login-label--font-weight: 400;
//   --theme__login-label--color: #c4c4c4;
//   --theme__login-label--font-family: "p22-underground-regular", sans-serif;

//   --theme__login-input--font-size: 14px;
//   --theme__login-input--font-weight: 400;
//   --theme__login-input--color: #000000;
//   --theme__login-input--border-radius: 0;
//   --theme__login-input--border: 1px solid #eaeeef;
//   --theme__login-input--invalid-border-color: #ff4e4e;
//   --theme__login-input--box-shadow: none;
//   --theme__login-input--invalid-box-shadow: none;
//   --theme__login-input--font-family: "p22-underground-regular", sans-serif;
//   --theme__login-input-placeholder--color: #c4c4c4;

//   --theme__primary--color: #000000; /* hex code of a color */

//   --theme__primary-btn--color: #fff; /* hex code of a color */
//   --theme__primary-btn--background-color: #000000; /* hex code of a color */
//   --theme__primary-btn--border-width: 1px; /* in pixel or any alike valid css unit */
//   --theme__primary-btn--border-style: solid; /* border style value. default: solid */
//   --theme__primary-btn--border-color: #000000; /* hex code of a color */
//   --theme__primary-btn--border-radius: 0px; /* in pixel or any alike valid css unit */

//   --theme__secondary-btn--color: #000000; /* hex code of a color */
//   --theme__secondary-btn--background-color: #fff; /* hex code of a color */
//   --theme__secondary-btn--border-width: 1px; /* in pixel or any alike valid css unit */
//   --theme__primary-btn--border-style: solid; /* border style value. default: solid */
//   --theme__secondary-btn--border-color: #000000; /* hex code of a color */
//   --theme__secondary-btn--border-radius: 0px; /* in pixel or any alike valid css unit */

//   // Default
//   //   --theme__primary--color: #f9644a; /* hex code of a color */

//   // --theme__primary-btn--color: #fff; /* hex code of a color */
//   // --theme__primary-btn--background-color: #f9644a; /* hex code of a color */
//   // --theme__primary-btn--border-width: 1px; /* in pixel or any alike valid css unit */
//   // --theme__primary-btn--border-style: solid; /* border style value. default: solid */
//   // --theme__primary-btn--border-color: #f9644a; /* hex code of a color */
//   // --theme__primary-btn--border-radius: 4px; /* in pixel or any alike valid css unit */

//   // --theme__secondary-btn--color: #f9644a; /* hex code of a color */
//   // --theme__secondary-btn--background-color: #fff; /* hex code of a color */
//   // --theme__secondary-btn--border-width: 1px; /* in pixel or any alike valid css unit */
//   // --theme__primary-btn--border-style: solid; /* border style value. default: solid */
//   // --theme__secondary-btn--border-color: #f9644a; /* hex code of a color */
//   // --theme__secondary-btn--border-radius: 4px; /* in pixel or any alike valid css unit */
// }

$default-primary-color: #ff583e;
$default-primary-color-var1: rgba($default-primary-color, 0.7);
$default-primary-color-var2: rgba($default-primary-color, 0.45);
$default-primary-color-var3: rgba($default-primary-color, 0.25);
$default-primary-color-var4: rgba($default-primary-color, 0.05);

$primary-color: var(--theme__primary--color, $default-primary-color);
$primary-color-var1: var(
  --theme__primary--color-variant1,
  $default-primary-color-var1
);
$primary-color-var2: var(
  --theme__primary--color-variant2,
  $default-primary-color-var2
);
$primary-color-var3: var(
  --theme__primary--color-variant3,
  $default-primary-color-var3
);
$primary-color-var4: var(
  --theme__primary--color-variant4,
  $default-primary-color-var4
);

@mixin primary-bg-color-var4 {
  background-color: $primary-color-var4;
}

@mixin primary-bg-color-var1 {
  background-color: $primary-color-var1;
}

@mixin primary-font-color-var1 {
  color: $primary-color;
}

@mixin primary-bg-color-var2 {
  background-color: $primary-color-var2;
}

@mixin primary-bg-color-var3 {
  background-color: $primary-color-var3;
}

@mixin primary-icon-color-var1 {
  fill: $primary-color-var1;
}

@mixin primary-border-color-var3 {
  border: 1px solid $primary-color-var3 !important;
}

@mixin select-box-primary-border-color {
  border-radius: 4px;
  border: 2px solid $primary-color-var1;
}

@mixin primary-border-color-only-var3 {
  border-color: $primary-color-var3;
}

@mixin br-4 {
  border-radius: 4px !important;
}

.primary-font-color {
  @include primary-font-color-var1;
}

.primary-bg-color-var2 {
  @include primary-bg-color-var2;
}

.primary-border-color-only-var3 {
  @include primary-border-color-only-var3;
}

.primary-icon-color-var1 {
  @include primary-icon-color-var1;
}

.primary-bg-color-var4 {
  @include primary-bg-color-var4;
}

.primary-bg-color-var1 {
  @include primary-bg-color-var1;
}

.primary-bg-color-var3 {
  @include primary-bg-color-var3;
}

.primary-icon-color-var1 {
  @include primary-icon-color-var1;
}
.primary-border-color-var3 {
  @include primary-border-color-var3;
  @include br-4;
}

.br-4 {
  @include br-4;
}
