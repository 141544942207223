@import './login/index';

$break-small: 767px;

/* login */
#static-col,
#static-col-gift {
  position: fixed;
  left: 0px;
  width: 50%;
  top: 50px;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: $break-small) {
    display: none;
  }
}

.login-wrapper {
  height: calc(100vh - var(--offset, 0px));
}

.login-row {
  background: white;
  min-height: 100%;
}

#login-col-offset {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  height: inherit;

  @media (max-width: 767px) {
    display: none;
    height: 130px !important;
    background-position: center top;
  }

  &.login-col-no-image {
    background-image: url(https://uploads-ssl.webflow.com/5b367d6af68bd31cd7f6839e/5b445ea6060a4b4ffb735d97_Middel%201.svg);
    position: relative;
    &::after {
      content: 'Powered by AspenCX';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      color: white;
    }
  }
}

#login-col {
  // padding-top: 72px;
  // height: 100%;
  .pop-panel {
    .alert {
      font-size: 14px;
    }
  }
  .FormSuggestionText {
    font-size: 14px;
    text-align: left;
    margin-top: 4px;
    color: #ff4e4e;
  }
}
.login-aspenBenefits {
  background: white;
  padding-top: 10px;
  .benefit-col {
    text-align: center;
    // border-right: solid 1px #f1ada0;
    @media (max-width: 767px) {
      border: none;
      margin-top: 16px;
    }
    &-label {
      font-size: 15px;
      font-weight: 600;
      padding-top: 24px;
      letter-spacing: 2px;
      text-align: center;
      color: var(--theme__highlight--color, $primaryColor);
    }
  }
  .benefit-fill {
    fill: var(--theme__highlight--color, $primaryColor);
  }
}

.login-form-col {
  margin: 0;
  padding: 0;
}

.login-image-col {
  padding: 0;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.login-col-wrapper {
  padding: 30px 0px;
}

.floating {
  position: absolute;
  max-width: 440px;
  // min-width: 30%;
  z-index: 1;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  .login-col-wrapper {
    padding: 14px 24px 24px;
    border-radius: 8px;
    backdrop-filter: blur(2px);
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }

  .navbar-brand {
    padding: 0;
    margin: 0 0 24px;

    .logo-img {
      max-height: 80px;
      max-width: 300px;
      object-fit: contain;
    }

    .logo-img-text {
      color: #2c405a;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0.5px;

      @media (max-width: 575px) {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    top: 56px;
    transform: translateX(-50%);

    .login-col-wrapper {
      box-shadow: none;
      backdrop-filter: unset;
      background-color: transparent;
    }
  }
}

.login-swipe-dots {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .dots {
    width: 7px;
    height: 7px;
    background: #eaeaea;
    margin: 20px 10px;
    &.active {
      background-color: #c1c1c1;
    }
    border-radius: 50%;
  }
}
