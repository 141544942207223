@import './notification-banner';

.shipment-tracking {
  &__notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 90%;
    width: 100%;
  }
  &__container {
    margin-bottom: 20px;
    margin-top: 40px;
    @media (min-width: 1200px) {
      max-width: 1440px;
    }
    @media (min-width: 768px) and (max-width: 992px) {
      max-width: 992px;
    }
    @media (max-width: 575px) {
      max-width: 576px;
      padding: 0px;
      margin-top: 15px;
    }
    .bottom-highlights {
      position: fixed;
      right: 10%;
      bottom: 20px;
      margin-right: -30px;
      .img-wrapper {
        width: 60px;
        height: 60px;
        background: black;
        border-radius: 50%;
      }
    }
  }
  margin-top: 50px;
  &__row {
    display: flex;
    justify-content: center;
    margin: 0 0 60px;
    // min-height: calc(100vh - 124px);
    @media (max-width: 1200px) {
      margin: 0;
    }
    @media (max-width: 575px) {
      // margin: 0;
    }
    @media (max-width: 767px) {
      // margin: 0;
      .hide-shipment-timeline {
        display: none;
      }
    }
    @media (max-width: 575px) {
      margin: 0 0 15px;
    }
  }
  &__card {
    position: relative;
    border-radius: 12px;
    box-shadow: 0 20px 32px 0 rgba(124, 152, 182, 0.1);
    background-color: #ffffff;
    padding: 30px 20px;
    margin-bottom: 10px;
    //Need to remove width and height
    width: 100%;
    // height: 600px;
    height: 100%;
    overflow: auto;

    @media (max-width: 768px) {
      height: auto;
      max-height: 450px;
    }
    .estimated__info {
      border-bottom: 1px solid #dfe3eb;
      padding-bottom: 1em;
      &-alert {
        border-radius: 8px;
        background-color: rgba(255, 224, 75, 0.2);
        font-size: 16px;
        line-height: 1.38;
        color: #001234;
        padding: 16px 24px;
        margin-top: 16px;
      }

      .title {
        font-size: 20px;
        font-weight: 500;
        color: #001234;
      }
      .date {
        font-size: 44px;
        font-weight: 600;
        color: #001234;
        padding-bottom: 24px;
        @media (max-width: 360px) {
          font-size: 36px;
        }
        &.date-fallback {
          font-size: 14px;
          color: #33475b;
        }
      }
      .status {
        border-radius: 16px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        padding: 4px 20px;
        background-color: #ff7e4e;
        &.transit {
          background-color: #ff7e4e;
        }
        &.delivered {
          background-color: #2bc584;
        }
      }
    }
    .shipment__customer-details {
      padding: 18px 0px 18px 0px;
      .cname {
        font-size: 14px;
        font-weight: 500;
        color: #001234;
      }
      .address {
        font-size: 14px;
        color: #7c98b6;
      }
    }
    .email-subscribe {
      padding: 26px 0px;
      cursor: pointer;
      &-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.33;
        color: #001234;
      }
      &-subTitle {
        font-size: 12px;
        line-height: 1.33;
        color: #7c98b6;
      }
    }
    .shipment__timeline {
      &.timeline-center-noData {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
      &.timeline-height {
        height: 200px;
        position: relative;
      }
      &-image {
        cursor: pointer;
        &.reload {
          animation: rotation 2s infinite linear;
        }
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
      }
      &-noData {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex-grow: 1;
        .text {
          font-size: 12px;
          margin-bottom: 12px;
          color: #7c98b6;
        }
        .retry {
          padding: 6px 20px;
          border-radius: 16px;
          box-shadow: 0 3px 6px 0 rgba(0, 18, 52, 0.05);
          border: solid 1px #eaf0f9;
          background-color: #ffffff;
          font-size: 13px;
          font-weight: 500;
          color: #ff7e4e;
          &:active {
            background-color: #ffffff;
            color: #ff7e4e;
            border-color: #ff7e4e;
            box-shadow: none;
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      &-title {
        font-size: 16px;
        font-weight: 600;
        color: #33475b;
        // padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #f5f8fa;
        display: flex;
        justify-content: space-between;
        &.top-padding {
          padding-top: 20px;
        }
      }
      &-row {
        padding: 10px 0px;

        //  padding-bottom: 10px;
        border-bottom: 1px solid #f5f8fa;
        .label {
          width: 12px;
          height: 12px;
          background-color: #ff7e4e;
          border-radius: 50%;
        }
        .shipment-info {
          font-size: 14px;
          line-height: 1.43;
          color: #33475b;
        }
        .shipment-time {
          font-size: 13px;
          color: #7c98b6;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .delivered__updates {
      // padding-bottom: 40px;
      border-bottom: 1px solid #eaf0f9;
      &-image {
        text-align: center;
      }
      &-title {
        font-size: 22px;
        line-height: 1.27;
        text-align: center;
        padding: 20px 48px;
        color: #001234;
      }
      &-button {
        &.received {
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 rgba(255, 126, 78, 0.2);
          border: solid 1px #ff7e4e;
          background-color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.43;
          text-align: center;
          color: #ff7e4e;
          width: 100%;
          margin-bottom: 6px;
        }
        &.not-received {
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 rgba(0, 18, 52, 0.05);
          border: solid 1px #dfe3eb;
          background-color: #ffffff;
          width: 100%;
          font-size: 14px;
          margin-top: 6px;
          font-weight: 500;
          text-align: center;
          color: #7c98b6;
        }
      }
      .feedback-options {
        text-align: center;
        &-button {
          border: solid 1px #cbd6e2;
          background-color: #ffffff;
          font-size: 18px;
          color: #516f90;
          border-radius: 50%;
          margin-right: 12px;
          &:last-child {
            margin-right: 0px;
          }
          &:active {
            box-shadow: none;
          }
          &:focus {
            box-shadow: none;
          }
          &.button-active {
            background-color: #001234;
            color: #ffffff;
          }
        }
        &-message {
          padding: 20px 0px;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.13;
          text-align: center;
          color: #001234;
          &.select-rating-text {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.13;
            text-align: center;
            color: #7c98b6;
          }
        }
      }
      .feedback-submit {
        // padding-top: 32px;
        button {
          &:focus {
            box-shadow: none;
          }
        }
        &-cancel {
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 rgba(0, 18, 52, 0.05);
          border: solid 1px #dfe3eb;
          background-color: #ffffff;
          width: 100%;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: #7c98b6;
          &:active {
            background-color: #ffffff;
            color: #7c98b6;
            border-color: #7c98b6;
          }
        }
        &-submit {
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 rgba(255, 126, 78, 0.2);
          border: solid 1px #ff7e4e;
          background-color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.43;
          text-align: center;
          color: #ff7e4e;
          width: 100%;
          &:active {
            background-color: #ffffff;
            color: #ff7e4e;
            border-color: #ff7e4e;
          }
        }
      }
    }
  }

  &__campaign {
    display: flex;
    flex-direction: column;

    a img {
      border-radius: 12px;
    }

    a ~ a {
      margin-top: 30px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
      margin-top: 30px;
      flex-direction: row;

      a ~ a {
        margin-top: 0;
        margin-left: 30px;
      }
    }

    @media (max-width: 768px) {
      a ~ a {
        margin-top: 10px;
      }
    }

    @media (max-width: 575px) {
      a ~ a {
        margin-top: 10px;
      }
    }
  }
  &__carrier {
    padding: 18px 0px;
    border-bottom: 1px solid #eaf0f9;
    .carrier {
      font-size: 14px;
      font-weight: 500;
      color: #001234;
    }
    .tracking-number {
      font-size: 14px;
      color: #7c98b6;
    }
    .tracking-url {
      text-decoration: underline;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    &.container {
      max-width: 930px;
    }
  }
}
