.logo-stamp-wrapper {
  position: fixed;
  right: 0;
  bottom: 20px;
  padding: 8px 8.6px 11px 12px;
  opacity: 0.6;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #000;
  cursor: pointer;

  width: 95px;
  height: 39px;

  svg {
    fill: white;
  }
}
