@import '../../mixins';
@import '../../variables';

.ErrorPage {
  // @include header-margin();
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 15vh;

  &__image {
    margin-bottom: 1.5rem;

    img {
      max-height: 300px;
    }
  }

  &__text {
    color: #3f3f3f;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    white-space: pre-line;
  }

  &__button {
    padding: 14px 20px !important;
  }

  &__link {
    margin-top: 20px;
  }
}

@media (max-width: 640px) {
  & .ErrorPage {
    padding-top: 5vh;
  }
}
