.ShipmentInformation {
  &__header {
    display: flex;
    align-items: center;
    // padding: 1.5rem;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid #e5e5e5;
    gap: 10px;
  }

  &__status {
    width: 30%;
    flex-shrink: 0;
    // margin-right: 0.5rem;
  }

  &__title {
    // font-weight: 500;
    // font-size: 1.5rem;
    // color: $black;
    // margin-bottom: 0;
  }

  &__subtitle {
    color: #7c98b6;
    font-size: 12px;
  }

  &__addressTitle {
    font-size: 14px;
    color: #7c98b6;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  &__addressData {
    font-size: 12px;
  }

  &__body {
    // padding: 1.5rem;
    padding: 1rem 0.5rem;
  }

  .tab-overview {
    // max-height: 500px;
    // overflow: auto;
  }

  .updates-tab {
    height: 300px;
    overflow-y: auto;

    @media (max-width: 767px) {
      height: auto;
    }
  }

  &__map {
    height: 190px;
  }

  .Tabs {
    // margin-top: -1rem;
  }

  .Tabs.nav-tabs .nav-link {
    color: #516f90;
    margin-left: 0;
    margin-right: 1.5rem;
    cursor: pointer;
    background-color: #f6f6f6;
    border: 1px solid transparent;

    &.active {
      color: var(--theme__primary--color, $primaryColor);
      border-color: var(--theme__primary--color, $primaryColor);
    }
  }

  .Tabs.nav-tabs {
    border-bottom: 0;
    margin-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .ShipmentInformation {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0;
    }
    &__status {
      width: 100%;
      // margin-bottom: 1rem;
    }
    &__body {
      padding: 10px 0;
    }
  }
}
