$break-small: 480px;

.dropdown-header {
  padding: 8px 20px;
  background: #e4e7ea;
  border-bottom: 1px solid #c8ced3;
}
.dropdown-header .btn {
  margin-top: -7px;
  color: #73818f;
}
.dropdown-header .btn:hover {
  color: #23282c;
}
.dropdown-header .btn.pull-right {
  margin-right: -20px;
}

.dropdown-menu-lg {
  width: 250px;
}
.dropdown-menu {
  &.show {
    box-shadow: 0 6px 12px 0 rgba(51, 71, 91, 0.05);
    background-color: #ffffff;
    // top: 17px !important;
    border: none;
    border-radius: 0px;
  }
}

.app-header .navbar-nav .dropdown-menu {
  position: absolute;
}

.app-header .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.app-header .navbar-nav .dropdown-menu-left {
  right: auto;
  left: 0;
}

.app-header .navbar {
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.logout {
  img {
    position: absolute;
    right: 0px;
  }
}

.app-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  color: #23282c;
  background: #f0f3f5;
  border-top: 1px solid #c8ced3;
}

.row.row-equal {
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin-right: -15px;
  margin-left: -15px;
}
.row.row-equal [class*='col-'] {
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.main .container-fluid {
  padding: 0 30px;
}

.app-header {
  position: relative;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0;
  margin: 0;
  // border-bottom: 1px solid #c8ced3;
  box-shadow: 0 2px 4px 0 rgba(12, 19, 26, 0.1);
}
.app-header .navbar-brand {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  // width: 155px;
  height: 55px;
  padding: 0;
  margin-right: 0;
  background-color: transparent;
}
.app-header .navbar-brand .navbar-brand-minimized {
  display: none;
}
.app-header .navbar-toggler {
  min-width: 50px;
  padding: 0.25rem 0;
}
.app-header .navbar-toggler:hover .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%232f353a' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.app-header .navbar-toggler-icon {
  height: 23px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2373818f' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.app-header .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
}
.app-header .nav-item {
  position: relative;
  min-width: 50px;
  margin: 0;
  text-align: center;
}
.app-header .nav-item button {
  margin: 0 auto;
}

.app-header .nav-item:last-child .nav-link {
  padding-right: 0;
}

.app-header .nav-item .nav-link .badge {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: 0;
}
.app-header .nav-item button {
  background: none !important;
  box-shadow: none !important;
}
.app-header .nav-item .nav-link .img-avatar,
.app-header .nav-item .avatar.nav-link img {
  height: 35px;
  margin-left: 10px;
  border-radius: 50%;
}
.app-header .nav-item .nav-link .email {
  font-size: 11px;
}
.app-header .dropdown-menu {
  padding-bottom: 0;
  padding-top: 0px;
  line-height: 1.5;
  overflow: hidden;
}
.app-header .dropdown-item {
  // border-bottom: 1px solid #eef2fa;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 12px 20px;
  color: var(--theme__header-link--color, #73818f);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg > g {
    stroke: var(--theme__header-link--color, #73818f);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
  }
  &:hover {
    background-color: rgba(62, 62, 255, 0.04);
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--theme__primary--color, $primaryColor);
    svg > g {
      stroke: var(--theme__primary--color, $primaryColor);
    }
    &::before {
      opacity: 0.1;
      background-color: var(--theme__primary--color, $primaryColor);
    }
  }
}

.navbar-nav .nav-link {
  color: #73818f;
}
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: #2f353a;
}

.navbar-nav .open > .nav-link,
.navbar-nav .open > .nav-link:hover,
.navbar-nav .open > .nav-link:focus,
.navbar-nav .active > .nav-link,
.navbar-nav .active > .nav-link:hover,
.navbar-nav .active > .nav-link:focus,
.navbar-nav .nav-link.open,
.navbar-nav .nav-link.open:hover,
.navbar-nav .nav-link.open:focus,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.active:hover,
.navbar-nav .nav-link.active:focus {
  color: #2f353a;
}

.navbar-divider {
  background-color: rgba(0, 0, 0, 0.075);
}

@media (min-width: 992px) {
  .brand-minimized .app-header .navbar-brand {
    width: 50px;
    background-color: transparent;
  }
  .brand-minimized .app-header .navbar-brand .navbar-brand-full {
    display: none;
  }
  .brand-minimized .app-header .navbar-brand .navbar-brand-minimized {
    display: block;
  }
}

#header {
  .loggedOut {
    max-width: 100vw;
    padding: 0 100px;
    @media (max-width: 1200px) {
      padding: 0 75px;
    }
    @media (max-width: 1023px) {
      padding: 0 50px;
    }
    @media (max-width: 599px) {
      padding: 0 25px;
    }
  }

  .logo-img {
    max-height: 100%;
    max-width: 300px;
    object-fit: contain;
  }

  .logo-img-text {
    color: #2c405a;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.5px;

    @media (max-width: 575px) {
      font-size: 20px;
    }
  }

  .headerDefault {
    background-color: #ffffff;
  }
}
@media screen and (max-width: $break-small) {
  .mobile-link {
    display: none;
  }

  #header .logo-img {
    max-width: 120px;
  }
}
