.link {
  margin: 24px 0;
  padding: 8px 16px;
  border-radius: 8px;
  display: inline-block;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

  &:hover {
    color: inherit;
    opacity: 0.9;
  }

  @media (max-width: 767px) {
    left: 8px;
    top: 12px;
    padding: 6px 12px;
    text-decoration: underline;

    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
}
